import React, { useEffect, useState } from 'react'

// Appt Components
import Box from '../../components/simple/box/Box.js'
import Button from '../../components/simple/button/Button.js'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome.js'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import Divider from '../../components/simple/divider/Divider.js'
import H2 from '../../components/simple/heading/H2.js'
import RatingButtonGroup from '../../components/compound/ratingButtonGroup/RatingButtonGroup.js'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid.js'
import Text from '../../components/simple/text/Text.js'
import TextArea from '../../components/simple/input/TextArea.js'
import LocalStorageService from '../../services/LocalStorageService.js'

function DeescalationTechniques (props) {
  const [questions, setQuestions] = useState(props.questions)

  const [localIncidentValues, setLocalIncidentValues] = useState(props.incidentValues)
  const terms = LocalStorageService.get('terms')

  // const [deescalationTechniqueArray, setDeescalationTechniqueArray] = useState([])

  const previousPage = () => {
    // buildTechniqueArray()

    props.previousPage(localIncidentValues)
  }

  const nextPage = () => {
    // buildTechniqueArray()

    props.nextPage(localIncidentValues)
  }

  const submitForm = () => {
    // buildTechniqueArray()

    props.submitForm(localIncidentValues, 'Draft')
  }

  const buildForm = () => {
    const questionList = []
    questions.forEach((item, index) => {
      if (index !== questions.length - 1) {
        var value = 'n/a'
        if (localIncidentValues[item.key] && localIncidentValues[item.key] !== '') {
          value = localIncidentValues[item.key]
        }

        questionList.push(
          <FormField
            key={index}
            label={questions[index].questionText}
            name={questions[index].key}
          >
            <RatingButtonGroup
              clear='n/a'
              id={questions[index].key}
              name={questions[index].key}
              value={value}
              minRange={1}
              maxRange={10}
              step={1}
              disabled={props.formId}
            />
          </FormField>

        )
      } else {
        if (localIncidentValues.otherTechniques && localIncidentValues.otherTechniques !== 'n/a') {
          questionList.push(
            <FormField
              key={index}
              label={questions[index].questionText}
              name={questions[index].key}
            >
              <TextArea
                name={questions[index].key}
              />
            </FormField>
          )
        }
      }
    })

    return questionList
  }

  const buildTechniqueArray = () => {
    const techniqueArray = []

    questions.forEach((item, index) => {
      if (index !== questions.length - 1) {
        techniqueArray.push(
          {
            key: questions[index].key,
            value: localIncidentValues[questions[index].key]
          })
      }
    })

    // setDeescalationTechniques(JSON.stringify(techniqueArray))
    // props.setDeescalationTechniques(techniques => [...techniques, JSON.stringify(techniqueArray)])
    props.setDeescalationTechniques(techniqueArray)

    // setLocalIncidentValues(localValues => ({
    //   ...localValues,
    //   deescalationTechnique: JSON.stringify(techniqueArray)
    // }))
  }

  useEffect(() => {
    buildTechniqueArray()
  }, [localIncidentValues])

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small'>
      <Box
        gap='small'
        margin={{ horizontal: 'small' }}
        pad='small'
        round='small'
      >

        <Box fill direction='row' gap='small'>
          <H2 margin={{ vertical: 'none' }}>De-escalation Techniques used and effectiveness</H2>
          <Button
            label={<Text><FontAwesome color='brand' icon={['fas', 'question-circle']} /></Text>}
            plain
            tip={{
              content: (
                <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                  <Text>Please record the effectiveness of any de-escalation techniques. De-escalation techniques should also be used throughout the intervention.</Text>
                </Box>)
            }}
          />
        </Box>

        <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />
        <Form
          onChange={nextValue => {
            setLocalIncidentValues(nextValue)
          }}
          onSubmit={({ value: nextValue }) => {
            submitForm(nextValue)
          }}
          value={localIncidentValues}
        >

          {!questions?.length && <Text>No de-escalation techniques are setup for this {terms?.service || 'service'}.</Text>}

          {questions.length > 0 && <Box direction='row'>
            <Text margin={{ right: 'small' }}>1=Not Effective 10=Very Effective</Text>
            <Button
              label={<Text><FontAwesome color='brand' icon={['fas', 'question-circle']} /></Text>}
              plain
              tip={{
                content: (
                  <Box background={{ color: 'white' }} border={{ color: 'brand', size: 'small' }} direction='column' pad='small'>
                    <Text>In your opinion how effective was the de-escalation techniques used.</Text>
                  </Box>)
              }}
            />
            </Box>}

          <ResponsiveGrid
            rows={['auto']}
            columns={{
              small: ['auto'],
              medium: ['auto', 'auto'],
              large: ['auto', 'auto'],
              xlarge: ['auto', 'auto']
            }}
            gap='small'
          >
            {buildForm()}
          </ResponsiveGrid>

          <Divider color='primary' margin={{ top: 'medium', bottom: 'none' }} />

          <Box direction='row' gap='small' justify='between' margin={{ top: 'medium' }}>
            <Button label='< Back' onClick={() => previousPage()} secondary />
            {!props.formId && <Button icon={<FontAwesome icon={['fal', 'save']} color='#fff' />} label='Save for later' onClick={() => submitForm()} />}
            <Button label='Next Page' onClick={() => nextPage()} primary />
          </Box>
        </Form>
      </Box>
    </Box>
  )
}
export default DeescalationTechniques
