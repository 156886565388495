import React, { useEffect, useState } from 'react'

import { useHistory } from 'react-router-dom'

// Grommet
import { ResponsiveContext } from 'grommet'

import { capitalize } from '../../utils/text'
import { getObjectByValue } from '../../utils/objects'

// Spinner
import PulseLoader from 'react-spinners/PulseLoader'

// Services
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import UserService from '../../services/UserService'
import UserTagService from '../../services/UserTagService'
import OrganisationTagService from '../../services/OrganisationTagService'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import DataTable from '../../components/simple/dataTable/DataTable.js'
import Text from '../../components/simple/text/Text'

import NotificationLayer from '../../components/compound/notification/NotificationLayer'

// Shared Components
import BreadcrumbBar from '../shared/BreadcrumbBar'

function ServiceUserList (props) {
  const [userValues, setUserValues] = useState([])
  const history = useHistory()
  const [userToDelete, setUserToDelete] = useState(null)
  const [showDeleteOptions, showDelete] = useState(false)
  // const { error, showError } = useErrorHandler(null)
  const [loading, setLoading] = useState(false)
  const [hasTerms, setHasTerms] = useState(false)

  const [showFormerStudents, setShowFormerStudents] = useState(false)

  const apiToken = LocalStorageService.get('apiToken')


  // DataTable sort state
  const [sort, setSort] = useState({
    property: 'learnerNumber',
    direction: 'asc'
  })

  const terms = LocalStorageService.get('terms')

  const activeOrg = LocalStorageService.get('activeOrg')

  // Prompt user to delete
  const showDeleteUserOptions = (user) => {
    setUserToDelete(user)
    showDelete(true)
  }

  // Delete a User
  const deleteUser = async () => {
    const apiToken = LocalStorageService.get('apiToken')
    // Delete from server
    const response = await UserService.deleteUser(apiToken, userToDelete)
    if (response.data.success === 'true') {
      // And from state to force a refresh
      const newUserValues = userValues.filter((item) => item.id !== userToDelete.id)
      setUserValues(newUserValues)
      NotificationService.success(`${terms.serviceUser} deleted`)
    } else {
      NotificationService.error(response.error)
    }
    showDelete(false)
  }

  useEffect(() => {

    const checkForTerms = async () => {

      const params = { fields: 'type,key,value' }
      const where = { type: 'besmart' }
      const organisationTags = await OrganisationTagService.get(apiToken, params, where)

      // Override terms if set
      if (organisationTags) {
        const academicYearStartDay = organisationTags.find(tag => (tag.type === 'besmart' && tag.key === 'academicYearStartDay'))
        setHasTerms(academicYearStartDay?.value?.length ? true : false)
      }
    }
    checkForTerms()

   
  }, [])

  // Basically componentDidMount
  useEffect(() => {
    let unmounted = false

    // Get Users for this organisation
    const getUsers = async () => {
      const apiToken = LocalStorageService.get('apiToken')

      let params = {
        fields: 'id,firstName,lastName,reference',
        limit: 1000,
        orgId: activeOrg.id,
        type: 'student',
        showFormerStudents: showFormerStudents
        // where: { type: 'student' }
      }

      setLoading(true)
      setUserValues([])
      const users = await UserService.getUsers(apiToken, params)
      if (users?.error) {
        // NotificationService.error(users.error)
      } else {
        if (!unmounted) {
          if (users?.error) {
            setUserValues(null)
          } else if (users?.data) {
            // Now get the academicYear tags and match them to the User
            params = {
              fields: 'id,user,value,key,organisation',
              limit: 1000,
              orgId: activeOrg.id,
              type: 'student'
            }

            const where = {
              key: 'academicYear'
            }
            const yearGroups = await UserTagService.get(apiToken, params, where)

            users.data.forEach(user => {
              const userInGroup = getObjectByValue(yearGroups, 'user', user.id)
              if (userInGroup) {
                user.yearGroup = userInGroup.value
              }
            })

            const mappedUsers = users.data.map((data, index) => ({
              id: data.id,
              learnerNumber: data.reference,
              name: data.firstName + ' ' + data.lastName,
              yearGroup: data.yearGroup
            }))

            setUserValues(mappedUsers)
          }
        }
      }
    }

    getUsers()
    setLoading(false)

    return () => { unmounted = true }
  }, [showFormerStudents])

  const dataColumns = [
    {
      property: 'name',
      header: <Text weight={600}>Name</Text>,
      primary: true,
      search: true
    },
    {
      property: 'learnerNumber',
      header: <Text weight={600}>Learner Number</Text>,
      primary: true,
      search: true
    },
    {
      property: 'yearGroup',
      header: <Text weight={600}>Year Group</Text>,
      search: true
    },
    {
      property: 'actions',
      header: <Text weight={600}>Actions</Text>,
      render: datum => (
        <Box direction='row'>
          <Button
            href={`/serviceuser/${datum.id}/profile`}
            target='_self'
            plain
            children={
              <Box pad='5px'>
                <FontAwesomeIcon icon={['fal', 'eye']} color='#fe8300' />
              </Box>
            }
          />
          <Button
            href={`/serviceuser/${datum.id}/edit`}
            target='_self'
            plain
            children={
              <Box pad='5px'>
                <FontAwesomeIcon icon={['fal', 'edit']} color='#fe8300' />
              </Box>
            }
          />
          <Button
            target='_self'
            plain
            onClick={() => showDeleteUserOptions(datum)}
            children={
              <Box pad='5px'>
                <FontAwesomeIcon icon={['fal', 'trash']} color='#fe8300' />
              </Box>
            }
          />
        </Box>
      ),
      sortable: false
    }
  ]

  return (
    <>
      {showDeleteOptions &&
        <NotificationLayer button1Text='Yes' button1Click={deleteUser} button2Text='No' button2Click={() => { showDelete(false) }}>Are you sure you want to delete user {userToDelete.name}?</NotificationLayer>}
      <BreadcrumbBar
        path={<><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        {capitalize(terms.serviceUser || 'Service User')} Directory
      </BreadcrumbBar>

      {/*       <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small'>
      <Box direction='column' pad={{ horizontal: 'medium' }}>
        <H1 margin={{ top: 'small', bottom: 'none' }}>{capitalize(terms.serviceUser || 'Service User')} Directory </H1>
        {showDeleteOptions &&
          <NotificationLayer button1Text='Yes' button1Click={deleteUser} button2Text='No' button2Click={() => { showDelete(false) }}>Are you sure you want to delete user {userToDelete.name}?</NotificationLayer>}
        <Text margin={{ bottom: 'small', top: 'xsmall' }} size='xsmall'><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></Text>
      </Box>
            </Box> */}

      <Box background='white' direction='column' flex='grow' gap='small' pad='medium' round='small'>
        <Box direction='column'>
          <Box alignSelf='end' direction='row-responsive' pad={{ horizontal: 'medium' }}>
            <Box direction='row' gap='xsmall'>
              {hasTerms && !showFormerStudents && <Button onClick={()=> setShowFormerStudents(true) } label={<Text style={{ textTransform: 'capitalize' }}><FontAwesomeIcon icon={['fal', 'user']} />&nbsp;&nbsp;Active {terms.serviceUserPlural || 'Service Users'}</Text>} secondary />}
              {hasTerms && showFormerStudents && <Button onClick={()=> setShowFormerStudents(false) } label={<Text style={{ textTransform: 'capitalize' }}><FontAwesomeIcon icon={['fal', 'clock']} />&nbsp;&nbsp;Former {terms.serviceUserPlural || 'Service Users'}</Text>} secondary />}
              <Button href='serviceuser/new/upload' label={<Text><FontAwesomeIcon icon={['fal', 'cloud-upload']} /> Bulk Upload</Text>} secondary />
              <Button href='serviceuser/new/edit' label={<Text><FontAwesomeIcon icon={['fal', 'plus-circle']} /> Add {terms.serviceUser || 'Service User'}</Text>} target='_self' />
            </Box>
          </Box>

          <Box direction='column' gap='medium' pad={{ horizontal: 'medium' }}>
            <Box align='center'>
              <PulseLoader
                size={15}
                color='orange'
                loading={loading}
              />
            </Box>
            {userValues && userValues.length
              ? (
                <ResponsiveContext.Consumer>
                  {responsive => (
                    <DataTable
                      columns={responsive === 'small' ? dataColumns.filter(el => el.property !== 'learnerNumber' && el.property !== 'yearGroup') : dataColumns}
                      data={userValues}
                      onSort={setSort}
                      step={2000}
                      // paginate
                      sort={sort}
                      // step={2}
                    />)}
                </ResponsiveContext.Consumer>)
              : <Text margin={{ vertical: 'small' }}>There are no records to display.</Text>}
          </Box>
        </Box>
      </Box>
    </>
  )
}

export default ServiceUserList
