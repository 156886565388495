import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider.js'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid'
import Text from '../../components/simple/text/Text'
import ReactToPrint from "react-to-print";

// Shared Components
import BreadcrumbBar from '../shared/BreadcrumbBar'
import RelatedIncidents from './common/RelatedIncidents.js';


import LocalStorageService from '../../services/LocalStorageService'
import { capitalize } from '../../utils/text'

import { Bar, Line, Polar } from 'react-chartjs-2'
import SimpleCounter from '../../components/compound/simpleCounter/SimpleCounter'
import { backgroundColours, borderColours } from './colours.js'

const IncidentReport = (props) => {
  const history = useHistory()

  const [totalNumberOfIncidentsByStudent, setTotalNumberOfIncidentsByStudent] = useState([])
  const [totalNumberOfIncidentsByYearGroup, setTotalNumberOfIncidentsByYearGroup] = useState([])
  const [totalNumberOfIncidentsByStudentGroup, setTotalNumberOfIncidentsByStudentGroup] = useState([])
  const [totalNumberOfIncidentsByDate, setTotalNumberOfIncidentsByDate] = useState([])
  const [totalNumberOfIncidentsByHour, setTotalNumberOfIncidentsByHour] = useState([])
  const [totalNumberOfIncidentsByLocation, setTotalNumberOfIncidentsByLocation] = useState([])
  const [totalNumberOfIncidentsBySystemUser, setTotalNumberOfIncidentsBySystemUser] = useState([])
  const [relatedIncidents, setRelatedIncidents] = useState([]);

  const terms = LocalStorageService.get('terms')

  // Incidents by Student
  const studentData = {
    // From props.location.state.toalNumberOfIncidentsByStudent.name
    labels: totalNumberOfIncidentsByStudent[1],
    datasets: [
      {
        // label: '# of Votes',
        data: totalNumberOfIncidentsByStudent[0],
        backgroundColor: backgroundColours,
        borderColor: borderColours,
        borderWidth: 1,
      },
    ],
  };

  // Incidents by Year Group
  const yeargroupData = {
    labels: totalNumberOfIncidentsByYearGroup[1],
    datasets: [
      {
        // label: '# of Votes',
        data: totalNumberOfIncidentsByYearGroup[0],
        backgroundColor: backgroundColours,
        borderColor: borderColours,
        borderWidth: 1,
      },
    ],
  };

  // Incidents by Student Group
  const studentgroupData = {
    labels: totalNumberOfIncidentsByStudentGroup[1],
    datasets: [
      {
        // label: '# of Votes',
        data: totalNumberOfIncidentsByStudentGroup[0],
        backgroundColor: backgroundColours,
        borderColor: borderColours,
        borderWidth: 1,
      },
    ],
  };

  // Location
  const locationData = {
    labels: totalNumberOfIncidentsByLocation[1],
    datasets: [
      {
        // label: '# of Votes',
        data: totalNumberOfIncidentsByLocation[0],
        backgroundColor: backgroundColours,
        borderColor: borderColours,
        borderWidth: 1,
      },
    ],
  };

  // User
  const userData = {
    labels: totalNumberOfIncidentsBySystemUser[1],
    datasets: [
      {
        // label: 'Users',
        data: totalNumberOfIncidentsBySystemUser[0],
        backgroundColor: backgroundColours,
        borderColor: borderColours,
        borderWidth: 1,
      },
    ],
  };

  // Incidents by Date
  const incidentsByDate = {
    labels: totalNumberOfIncidentsByDate[1], // ['Oct', 'Nov', 'Dec', 'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec', 'Jan'],
    datasets: [
      {
        // label: '# of Incidents',
        data: totalNumberOfIncidentsByDate[0],
        backgroundColor: backgroundColours,
        borderColor: borderColours,
        borderWidth: 1,
      },
    ],
  };

  // Incidents by hour
  const incidentsByHour = {
    labels: totalNumberOfIncidentsByHour[1], // ['00-01', '01-02', '02-03', '03-04', '04-05', '05-06', '06-07', '07-08', '08-09', '09-10', '10-11', '11-12', '12-13', '13-14', '14-15', '15-16'],
    datasets: [
      {
        // label: '# of Incidents',
        data: totalNumberOfIncidentsByHour[0],
        backgroundColor: backgroundColours,
        borderColor: borderColours,
        borderWidth: 1,
      },
    ],
  };

  const printScreen = () => {
    window.print()
  }

  useEffect(() => {
    // Map the API data in props.location.state into the relevant datasets

    // Student
    const totalNumberOfIncidentsByStudent = props?.location?.state?.totalNumberOfIncidentsByStudent

    if (totalNumberOfIncidentsByStudent?.length) {
      const totalNumberOfIncidentsByStudentLabels = totalNumberOfIncidentsByStudent.map((data, index) => {
        return `${data.name} (${data.count})` || 'n/a'
      })

      const totalNumberOfIncidentsByStudentData = totalNumberOfIncidentsByStudent.map((data, index) => {
        return data.count
      })

      setTotalNumberOfIncidentsByStudent([totalNumberOfIncidentsByStudentData, totalNumberOfIncidentsByStudentLabels])
    }

    // Year Group
    const totalNumberOfIncidentsByYearGroup = props?.location?.state?.totalNumberOfIncidentsByYearGroup

    if (totalNumberOfIncidentsByYearGroup?.length) {
      const totalNumberOfIncidentsByYearGroupLabels = totalNumberOfIncidentsByYearGroup.map((data, index) => {
        return data.yearGroup || 'n/a'
      })

      const totalNumberOfIncidentsByYearGroupData = totalNumberOfIncidentsByYearGroup.map((data, index) => {
        return data.count
      })

      setTotalNumberOfIncidentsByYearGroup([totalNumberOfIncidentsByYearGroupData, totalNumberOfIncidentsByYearGroupLabels])
    }

    // Student Group
    const totalNumberOfIncidentsByStudentGroup = props?.location?.state?.totalNumberOfIncidentsByStudentGroup

    if (totalNumberOfIncidentsByStudentGroup?.length) {
      const totalNumberOfIncidentsByStudentGroupLabels = totalNumberOfIncidentsByStudentGroup.map((data, index) => {
        return data.userGroup || 'n/a'
      })

      const totalNumberOfIncidentsByStudentGroupData = totalNumberOfIncidentsByStudentGroup.map((data, index) => {
        return data.count
      })

      setTotalNumberOfIncidentsByStudentGroup([totalNumberOfIncidentsByStudentGroupData, totalNumberOfIncidentsByStudentGroupLabels])
    }

    // Date
    const totalNumberOfIncidentsByDate = props?.location?.state?.totalNumberOfIncidentsByDate

    if (totalNumberOfIncidentsByDate?.length) {
      const totalNumberOfIncidentsByDateLabels = totalNumberOfIncidentsByDate.map((data, index) => {
        return data.month || 'n/a'
      })

      const totalNumberOfIncidentsByDateData = totalNumberOfIncidentsByDate.map((data, index) => {
        return data.count
      })

      setTotalNumberOfIncidentsByDate([totalNumberOfIncidentsByDateData, totalNumberOfIncidentsByDateLabels])
    }

    // Time
    const totalNumberOfIncidentsByHour = props?.location?.state?.totalNumberOfIncidentsByHour

    if (totalNumberOfIncidentsByHour?.length) {
      const totalNumberOfIncidentsByHourLabels = totalNumberOfIncidentsByHour.map((data, index) => {
        return data.hour || 'n/a'
      })

      const totalNumberOfIncidentsByHourData = totalNumberOfIncidentsByHour.map((data, index) => {
        return data.count
      })

      setTotalNumberOfIncidentsByHour([totalNumberOfIncidentsByHourData, totalNumberOfIncidentsByHourLabels])
    }

    // Location
    const totalNumberOfIncidentsByLocation = props?.location?.state?.totalNumberOfIncidentsByLocation

    if (totalNumberOfIncidentsByLocation?.length) {
      const totalNumberOfIncidentsByLocationLabels = totalNumberOfIncidentsByLocation.map((data, index) => {
        return data.response || 'n/a'
      })

      const totalNumberOfIncidentsByLocationData = totalNumberOfIncidentsByLocation.map((data, index) => {
        return data.count
      })

      setTotalNumberOfIncidentsByLocation([totalNumberOfIncidentsByLocationData, totalNumberOfIncidentsByLocationLabels])
    }

    // User
    const totalNumberOfIncidentsBySystemUser = props?.location?.state?.totalNumberOfIncidentsBySystemUser

    if (totalNumberOfIncidentsBySystemUser?.length) {
      const totalNumberOfIncidentsBySystemUserLabels = totalNumberOfIncidentsBySystemUser.map((data, index) => {
        return data.name || 'n/a'
      })

      const totalNumberOfIncidentsBySystemUserData = totalNumberOfIncidentsBySystemUser.map((data, index) => {
        return data.count
      })

      setTotalNumberOfIncidentsBySystemUser([totalNumberOfIncidentsBySystemUserData, totalNumberOfIncidentsBySystemUserLabels])
    }

    if (props?.location?.state?.incidentSearch !== null) {
      setRelatedIncidents(props.location.state.incidentSearch);
    }

  }, [])

  let componentRef = null
  let setComponentRef = (ref) => {
    componentRef = ref;
  };

  let reactToPrintContent = () => {
    return componentRef;
  };
  const reactToPrintTrigger = () => {
    return <Button label={<FontAwesome icon={['fal', 'print']} size='xs' />} className='noprint' plain target='_self' />
  };

  return (
    <>
      <BreadcrumbBar
        path={<><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        <Box direction='row' justify='between'>
          <Text>Global Incident Report</Text>
          <ReactToPrint
            content={reactToPrintContent}
            documentTitle="AwesomeFileName"
            removeAfterPrint
            trigger={reactToPrintTrigger}
        />
        </Box>
      </BreadcrumbBar>

      <Box ref={setComponentRef} direction='column' gap='small' round='small'>

        {/* <Grid
          rows={['auto', 'auto', 'auto']}
          columns={['auto', 'auto', 'auto']}
          gap='medium'
          areas={[
            { name: 'main', start: [0, 0], end: [1, 1] }
          ]}
        > */}

        <ResponsiveGrid
          rows={['auto']}
          columns={{
            small: ['auto'],
            medium: ['24.25%', '24.25%', '24.25%', '24.25%'],
            large: ['24.25%', '24.25%', '24.25%', '24.25%'],
            xlarge: ['24.25%', '24.25%', '24.25%', '24.25%']
          }}
          gap='small'
          pad='xxsmall'
        >

          <SimpleCounter count={props?.location?.state?.totalNumberOfIncidents ? String(props.location.state.totalNumberOfIncidents) : '0'} label='Total Number of Incidents' />
          <SimpleCounter count={props?.location?.state?.numberIncidentsNoPhysicalInterventionUsed ? String(props.location.state.numberIncidentsNoPhysicalInterventionUsed) : '0'} label='# Incidents that did not include Physical Intervention' />
          <SimpleCounter count={props?.location?.state?.numberIncidentsNoPhysicalInterventionUsed ? String(props.location.state.numberIncidentsPhysicalInterventionUsed) : '0'} label='# Incidents involving Physical Intervention' />
          <SimpleCounter count={props?.location?.state?.numberIncidentsRestrictedInterventionUsed ? String(props.location.state.numberIncidentsRestrictedInterventionUsed) : '0'} label={<Text># Incidents involving <Text weight="bold">Restrictive</Text> Physical Intervention</Text>} />
        </ResponsiveGrid>
        <ResponsiveGrid
          rows={['auto']}
          columns={{
            small: ['auto'],
            medium: ['auto'],
            large: ['auto'],
            xlarge: ['auto']
          }}
          gap='small'
        >
          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>{capitalize(terms?.serviceUser || 'Service User')}</Text>
            <Text margin={{ bottom: 'small' }} size='small'>Total number of Incident by {terms?.serviceUser || 'service user'}</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            {totalNumberOfIncidentsByStudent?.length !== 0
              ? (
                // <Polar
                //   data={studentData}
                //   height={250}
                //   width={150}
                //   options={{ maintainAspectRatio: false }}
                //   legend={{ display: true }}
                //   // width='400px'
                // />
                <Bar
                  data={studentData}
                  height={25}
                  legend={{ display: false }}
                  // options={{ maintainAspectRatio: false }}
                  width={50}
                />

              ) : (
                <Text textAlign='center'>Not enough data available</Text>
              )}
          </Box>
        </ResponsiveGrid>
        <ResponsiveGrid
          rows={['auto']}
          columns={{
            small: ['auto'],
            medium: ['auto', 'auto', 'auto'],
            large: ['auto', 'auto', 'auto'],
            xlarge: ['auto', 'auto', 'auto']
          }}
          gap='small'
        >

          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Year Group</Text>
            <Text margin={{ bottom: 'small' }} size='small'>Total number of Incidents</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            {totalNumberOfIncidentsByYearGroup.length !== 0
              ? (
                <Polar
                  data={yeargroupData}
                  height={250}
                  width={150}
                  options={{ maintainAspectRatio: false }}
                  legend={{ display: true }}
                  // options={{ maintainAspectRatio: false }}
                  // width={50}
                />)
              : (
                <Text textAlign='center'>Not enough data available</Text>
              )}
          </Box>


          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Year Group</Text>
            <Text margin={{ bottom: 'small' }} size='small'>Total number of Incidents</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            {totalNumberOfIncidentsByYearGroup.length !== 0
              ? (
                <Polar
                  data={yeargroupData}
                  height={250}
                  width={150}
                  options={{ maintainAspectRatio: false }}
                  legend={{ display: true }}
                  // options={{ maintainAspectRatio: false }}
                  // width={50}
                />)
              : (
                <Text textAlign='center'>Not enough data available</Text>
              )}
          </Box>

          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>{capitalize(terms?.serviceUser || 'Service User')} Group</Text>
            <Text margin={{ bottom: 'small' }} size='small'>Total number of Incidents</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            {totalNumberOfIncidentsByStudentGroup?.length !== 0
              ? (
                <Polar
                  data={studentgroupData}
                  height={250}
                  width={150}
                  options={{
                    maintainAspectRatio: false,
                  }}
                  legend={{ display: true }}
                  // options={{ maintainAspectRatio: false }}
                  // width={50}
                />)
              : (
                <Text textAlign='center'>Not enough data available</Text>
              )}
          </Box>
        </ResponsiveGrid>

        <ResponsiveGrid
          rows={['auto']}
          columns={{
            small: ['auto'],
            medium: ['auto'],
            large: ['auto'],
            xlarge: ['auto']
          }}
          gap='small'
        >
          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Date</Text>
            <Text margin={{ bottom: 'small' }} size='small'>Total number of Incidents</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            {totalNumberOfIncidentsByDate.length !== 0
              ? (
                <Bar
                  data={incidentsByDate}
                  height={25}
                  legend={{ display: false }}
                  // options={{ maintainAspectRatio: false }}
                  width={50}
                />)
              : (
                <Text textAlign='center'>Not enough data available</Text>
              )}
          </Box>
        </ResponsiveGrid>

        <ResponsiveGrid
          rows={['auto']}
          columns={{
            small: ['auto'],
            medium: ['auto'],
            large: ['auto'],
            xlarge: ['auto']
          }}
          gap='small'
        >
          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Time</Text>
            <Text margin={{ bottom: 'small' }} size='small'>Incidents by time of day</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            {totalNumberOfIncidentsByHour?.length !== 0
              ? (
                <Bar
                  data={incidentsByHour}
                  height={25}
                  legend={{ display: false }}
                  // options={{ maintainAspectRatio: false }}
                  width={50}
                />)
              : (
                <Text textAlign='center'>Not enough data available</Text>
              )}
          </Box>
        </ResponsiveGrid>

        <ResponsiveGrid
          rows={['auto']}
          columns={{
            small: ['auto'],
            medium: ['auto', 'auto'],
            large: ['auto', 'auto'],
            xlarge: ['auto', 'auto']
          }}
          gap='small'
        >
          {/* <Grid
          rows={['auto']}
          columns={['auto', 'auto']}
          gap='medium'
        > */}
          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>Location</Text>
            <Text margin={{ bottom: 'small' }} size='small'>All Locations</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            {totalNumberOfIncidentsByLocation?.length !== 0
              ? (
                <Polar
                  data={locationData}
                  legend={{ display: true }}
                  height={250}
                  width={150}
                  options={{ maintainAspectRatio: false }}
                />)
              : (
                <Text textAlign='center'>Not enough data available</Text>
              )}
          </Box>

          <Box
            background='white'
            direction='column'
            pad='small'
            round='small'
          >
            <Text size='medium' weight='bold'>User</Text>
            <Text margin={{ bottom: 'small' }} size='small'>Top Users</Text>
            <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

            {totalNumberOfIncidentsBySystemUser?.length !== 0
              ? (
                <Polar
                  data={userData}
                  legend={{ display: true }}
                  height={250}
                  width={150}
                  options={{ maintainAspectRatio: false }}
                />)
              : (
                <Text textAlign='center'>Not enough data available</Text>
              )}
          </Box>

        </ResponsiveGrid>
      </Box>
      <RelatedIncidents results={relatedIncidents} />
    </>
  )
}

export default IncidentReport
