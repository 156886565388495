
// const hideValues = false

import ApiService from './ApiService'

class AIService {
  request = async (apiToken, userId, submissionId, action) => {
    let url = '/ai/query/' + action

    if (!userId || !submissionId || !action) { return }
    
    let params = {}
    params.userId = userId
    params.submissionId = submissionId

    let body = {}

    try {
      // Need to change this to a POST
      var response = await ApiService.post(apiToken, url, params, body)
    } catch (err) {
      console.log(err)
    }
    console.log('AIService:LEGACY:request ', response)
    return response
  }


  queryIncident = async (apiToken, userId, submissionId, action, customPrompt) => {
    let url = '/ai/query/incident' + action

    if (!userId || !submissionId || !action) { return }
    
    let params = {}
    params.userId = userId
    params.submissionId = submissionId

    if (customPrompt) { params.prompt = customPrompt }

    let body = {}

    try {
      // Need to change this to a POST
      var response = await ApiService.post(apiToken, url, params, body)
    } catch (err) {
      console.log(err)
    }
    console.log('AIService:queryIncident:request ', response)
    return response
  }

  queryStudent = async (apiToken, userId, action, customPrompt) => {
    let url = '/ai/query/student/' + action

    if (!userId || !action) { return }
    
    let params = {}
    params.userId = userId
    if (customPrompt) { params.prompt = customPrompt }

    let body = {}

    try {
      // Need to change this to a POST
      var response = await ApiService.post(apiToken, url, params, body)
    } catch (err) {
      console.log(err)
    }
    console.log('AIService:queryStudent:request ', response)
    return response
  }
  queryService = async (apiToken, action, prompt) => {
    let url = '/ai/query/service/' + action

    if (!action) { return }
    
    let params = {}
    if (prompt) { params.prompt = prompt }

    let body = {}

    try {
      // Need to change this to a POST
      var response = await ApiService.post(apiToken, url, params, body)
    } catch (err) {
      console.log(err)
    }
    console.log('AIService:queryStudent:request ', response)
    return response
  }
  syncService = async (apiToken) => {
    let url = '/ai/sync/service'
 
    let params = {}

    let body = {}

    try {
      // Need to change this to a POST
      var response = await ApiService.post(apiToken, url, params, body)
    } catch (err) {
      console.log(err)
    }
    console.log('AIService:syncService:request ', response)
    return response
  }

}
export default new AIService()
