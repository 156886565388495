import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import LocalStorageService from '../../../services/LocalStorageService'
import NotificationService from '../../../services/NotificationService'
import ReportService from '../../../services/ReportService'
import UserTagService from '../../../services/UserTagService'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Anchor from '../../../components/simple/anchor/Anchor'
import Box from '../../../components/simple/box/Box'
import Button from '../../../components/simple/button/Button'
import Divider from '../../../components/simple/divider/Divider'
import FieldBox from '../shared/FieldBox.js'
import Form from '../../../components/simple/form/Form.js'
import FormField from '../../../components/simple/formField/FormField.js'
import FormFieldRequired from '../../../components/simple/formField/FormFieldRequired.js'
import JoinBox from '../shared/JoinBox.js'
import OperatorBox from '../shared/OperatorBox.js'
import Text from '../../../components/simple/text/Text'
import Select from '../../../components/simple/input/Select'
import TextInput from '../../../components/simple/input/TextInput'
import ValueBox from '../shared/ValueBox.js'

// utils
import { capitalize } from '../../../utils/text'

// Shared Components
import BreadcrumbBar from '../../shared/BreadcrumbBar'
import moment from 'moment'

function ReportSearch (props) {
  const history = useHistory()

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeUser = LocalStorageService.get('userDetails')

  // Count of number of rows of queries
  const [criteriaIndex, setCriteriaIndex] = useState(0)

  // Array of field types for the currently
  // selected Field box
  const [fields, setFields] = useState([])

  // ???
  const [query, setQuery] = useState({
    'Field-0': '',
    'Value-0': ''
  })

  // Add to Quick Search has been clicked
  // so request the name
  const [getQuickSearchName, displayQuickSearchName] = useState(false)

  // List of Quick Searches
  const [quickReportSearches, setQuickReportSearches] = useState([])

  // Selected Quick Search
  const [quickSearchSelected, setQuickSearchValue] = useState([])

  const [fullSelectedQuickSearch, setFullSelectedQuickSearch] = useState([])

  // Set the submission type for the prop passed in
  const [submissionType, setSubmissionType] = useState(props.submissionType)

  // Reports array for each report type
  const reportsByType = {
    incidentreport: [
      'totalNumberOfIncidentsByStudent',
      'totalNumberOfIncidentsByYearGroup',
      'totalNumberOfIncidentsByStudentGroup',
      'totalNumberOfIncidentsByDate',
      'totalNumberOfIncidentsByHour',
      'totalNumberOfIncidentsByLocation',
      'totalNumberOfIncidentsBySystemUser',
      'totalNumberOfIncidents',
      'numberIncidentsPhysicalInterventionUsed',
      'numberIncidentsNoPhysicalInterventionUsed',
      'numberIncidentsRestrictedInterventionUsed',
      'globalIncidentSearch'
    ],
    incidentreportExtras: [
      'pilExtrasByUserBullying',
      'pilExtrasByUserRacist',
      'pilExtrasByUserSexist',
      'pilExtrasByUserDisability',
      'pilExtrasByUserHomophobic',
      'pilExtrasByUserBiphobic',
      'pilExtrasByUserTransphobic',
      'pilExtrasByUserUseOfDerogatoryLanguage',
      'pilExtrasByUserSexualharassment',
      'pilExtrasByUserStudentOnStudent',
      'pilExtrasByUserSelfInjuriousBehaviour',
      'pilExtrasByUserSuspension',
      'pilExtrasByUserPermanentExclusion',
      'pilExtrasReport',
      'pilExtrasReportExclusions',
      'globalIncidentSearch'
    ],
    involvementreport: [
      'totalNumberOfIncidents',
      'numberOfStaffInvolvedPerIncident',
      'staffInvolvedInIncident',
      'otherChildrenInvolvedInIncident',
      'averageNumberOfStaffPerIncidentByDate',
      'totalNumberOfIncidentsByDate',
      'averageNumberOfStaffPerIncidentByHour',
      'averageNumberOfStaffPerIncidentByLocation',
      'globalIncidentSearch'
    ],
    medicalinterventionreport: [
      'numIncidentsInvolvingInjuryToChild',
      'numIncidentsInvolvingInjuryToStaff',
      'numIncidentsInvolvingInjuryToOthers',
      'numIncidentsInvolvingInjuryByStudentGroup',
      'numIncidentsInvolvingInjuryWithOtherChildren',
      // 'numberIncidentsRestrictedInterventionUsed',
      'totalNumberOfIncidentsByDate',
      'totalNumberOfIncidentsByHour',
      'averageNumberOfStaffPerIncidentByLocation',
      'otherChildrenInvolvedInIncident',
      'globalIncidentSearch'
    ],
    interventionreport: [
      'commonIntervention',
      'commonTechnique',
      'commonDeescalationTechnique',
      'commonPhysicalTechnique',
      'incidentDamageCount',
      'numberIncidentsRestrictedInterventionUsed',
      'averageDurationPhysicalIntervention',
      'totalDurationPhysicalIntervention',
      'breathingMonitoredByStudentGraph',
      'breathingMonitoredByYearGroupGraph',
      'breathingMonitoredByStudentGroupGraph',
      'globalIncidentSearch'
    ],
  };
  const goBack = () => {
    history.goBack()
  }

  // Save the Quick Search just to Local Storage for now
  const saveQuickSearch = async (searchesToSave) => {
    var searchToSubmit = []
    searchToSubmit.push({
      organisation: activeOrg.id,
      user: activeUser.id,
      key: 'quickSearch',
      type: `${submissionType}`,
      value: JSON.stringify(searchesToSave)
    })

    const tagSet = await UserTagService.set(apiToken, searchToSubmit)
    if (tagSet.error) {
      NotificationService.error(tagSet.error)
    } else {
      NotificationService.success('Quick Search has been saved')
    }
  }

  // Save the Quick Search just to Local Storage for now
  const submitQuickSearch = async (values) => {
    const query = await buildQuery()

    const queryObject = {}

    queryObject.name = values.quickSearchName
    queryObject.query = query

    // let quickSearch = LocalStorageService.get('quickReportSearch')

    // if (!quickSearch) {
    //   quickSearch = []
    // }

    // quickSearch.push(queryObject)

    // LocalStorageService.set('quickReportSearch', quickSearch)

    const searchToSubmit = []

    const quickSearchArray = quickReportSearches

    quickSearchArray.push(queryObject)

    searchToSubmit.push({
      organisation: activeOrg.id,
      key: 'quickSearch',
      type: `${submissionType}`,
      value: JSON.stringify(quickSearchArray),
      user: activeUser.id
    })

    const tagSet = await UserTagService.set(apiToken, searchToSubmit)

    if (tagSet.error) {
      NotificationService.error(tagSet.error)
    } else {
      values.quickSearchName = ''
      displayQuickSearchName(false)
      NotificationService.success('Quick Search has been saved')
    }
  }

  const removeQuickSearch = async () => {
    if (window.confirm('Are you sure you want to remove this quick search?')) {
      const valuesToSave = quickReportSearches.filter(s => s.name !== fullSelectedQuickSearch.name)

      console.log("fullSelectedQuickSearch", fullSelectedQuickSearch)
      console.log("quickReportSearches", quickReportSearches)
      console.log("valuesToSave", valuesToSave)

      await saveQuickSearch(valuesToSave)
      getQuickSearches()
    }
  }

  const getQuickSearches = () => {
    (async () => {
      const params = {
        fields: 'id,type,key,value',
        limit: 100,
        orgId: activeOrg.id
      }

      const where = {
        organisation: activeOrg.id,
        type: `${submissionType}`,
        key: 'quickSearch',
        user: activeUser.id
      }

      const quickSearches = await UserTagService.get(apiToken, params, where)

      var searches = []

      if (quickSearches !== undefined) {
        for (const search of quickSearches) {
          if (search.value) {
            try {
              var value = JSON.parse(search.value)
              if (value) {
                searches = value
              }
            } catch (err) {
              console.error(err)
            }
          }
        }
      }

      if (searches.length) {
        setQuickReportSearches(searches)
      }
    })()
  }

  // If a different field has been selected then
  // set the field type of the field selected
  // so that the Operator dropdown can be
  // populated accordingly
  const handleFieldChanged = (fieldName, value) => {
    const fieldIndex = fieldName.substring(fieldName.indexOf('-') + 1)
    const newFields = fields

    newFields[fieldIndex] = value

    setFields(newFields)
  }

  // Delete a Criteria row
  const removeCriteria = (index) => {
    console.log(index)

    // Remove the query
    if (query && query[`Field-${index}`]) {
      delete query[`Field-${index}`]
    }

    if (query && query[`Query-${index}`]) {
      delete query[`Query-${index}`]
    }

    if (query && query[`Value-${index}`]) {
      delete query[`Value-${index}`]
    }

    if (query && query[`Join-${index}`]) {
      delete query[`Join-${index}`]
    }

    // Rename the remaining query keys
    // eg. Field-1 -> Field-0
    //
    // if current key > index decrement by 1
    const newQuery = {}

    for (const item in query) {
      const fieldName = item.split('-')[0]
      let fieldIndex = item.split('-')[1]
      const fieldValue = query[fieldName.concat('-', fieldIndex)]

      if (parseInt(fieldIndex) > index) {
        fieldIndex--
      }

      newQuery[fieldName.concat('-', fieldIndex)] = fieldValue
    }

    console.log(newQuery)

    // Update new values
    const newFields = fields

    newFields.splice(index, 1)
    setFields(newFields)

    setQuery(newQuery)
    setCriteriaIndex(criteriaIndex - 1)
    buildCriteria()
  }

  // When Field changes default the Query box to the first option
  const setDefaultOperatorValue = (defautValue, index) => {
    if (query && query[`Field-${index}`]) {
      query[`Query-${index}`] = defautValue
    }
  }

  // One row of the query
  const criteriaRow = (index) => {
    return (
      <>
        <Box direction='row-responsive' gap='medium'>
          <FormField
            direction='column'
            label='Field'
            name={`Field-${index}`}
          >
            <FieldBox
              handleFieldChanged={handleFieldChanged}
              name={`Field-${index}`}
            />
          </FormField>

          <FormField
            direction='column'
            label='Query'
            name={`Query-${index}`}
          >
            <OperatorBox
              index={index}
              name={`Query-${index}`}
              type={fields[index]?.type}
              operators={fields[index]?.operators}
              setDefaultOperatorValue={setDefaultOperatorValue}
            />
          </FormField>

          <FormField
            direction='column'
            label='Value'
            name={`Value-${index}`}
          >
            <ValueBox
              name={`Value-${index}`}
              type={fields[index]?.type}
              list={fields[index]?.list}
              fieldType={fields[index]?.value}
            />
          </FormField>

          <Button label={<Text><FontAwesomeIcon icon={['fal', 'minus-circle']} /></Text>} margin={{ horizontal: 'xsmall', vertical: 'medium' }} style={{ background: '#333' }} onClick={() => removeCriteria(index)} target='_self' />
        </Box>
      </>

    )
  }

  // Build the whole form by adding one
  // query row for each join option added
  const buildCriteria = () => {
    const criteriaTable = []

    for (let i = 0; i <= criteriaIndex; i++) {
      criteriaTable.push(criteriaRow(i))
    }

    if (criteriaIndex > 0) {
      criteriaTable.push(
        <Box direction='row-responsive' gap='medium'>
          <FormField
            direction='column'
            label='Find results that match'
            name='Join'
          >
            <JoinBox
              name='Join'
            />
          </FormField>
        </Box>)
    }

    return criteriaTable
  }

  // Add another row to the query
  const addCriteria = () => {
    // default the Join to AND
    query[`Join-${(criteriaIndex + 1)}`] = 'AND'
    setQuery(query)
    setCriteriaIndex(criteriaIndex + 1)
  }

  // Using the entered values post the query to
  // get the relevant report information
  const buildQuery = () => {
    let endOfQuery = false
    let queryCounter = 0
    const queryArray = []

    // If no query return an empty array
    if (query !== undefined) {
      // Add each query row to an array
      while (!endOfQuery) {
        if (
          query[`Field-${queryCounter}`].value === 'deescalationTechniques' ||
          query[`Field-${queryCounter}`].value === 'physicalTechnique'
        ) {
          queryArray.push({
            key: query[`Value-${queryCounter}`].split(' ').join(''),
            operator: 'has',
            response: null,
            relation: queryCounter > 0 && query.Join ? query.Join : null
          })
        } else {
          queryArray.push({
            key: query[`Field-${queryCounter}`].value,
            operator: query[`Query-${queryCounter}`],
            response: (query[`Field-${queryCounter}`].type === 'autocomplete' || query[`Field-${queryCounter}`].type === 'integer' || query[`Field-${queryCounter}`].type === 'text' || query[`Field-${queryCounter}`].type === 'time') ? query[`Value-${queryCounter}`] : query[`Field-${queryCounter}`].type === 'date' ? moment(query[`Value-${queryCounter}`]).format('YYYY-MM-DD') : query[`Value-${queryCounter}`].value,
            relation: queryCounter > 0 && query.Join ? query.Join : null
          })
        }


        queryCounter++
        var checkNextCriteria = `Field-${queryCounter}` in query

        if (!checkNextCriteria) {
          endOfQuery = true
        }
      }
    }

    return queryArray
  }

  // Check a valid query has been entered
  const validateQuery = () => {
    // Check a query has been entered
    if (query === undefined) {
      return true
    }

    // allow default queries
    if (query['Field-0'] === '') {
      return true
    }

    // Now check each row has a Field, Operator and Value entered
    let endOfQuery = false
    let validQuery = true
    let queryCounter = 0

    while (!endOfQuery) {
      if (query[`Field-${queryCounter}`] === undefined || query[`Query-${queryCounter}`] === undefined || query[`Value-${queryCounter}`] === undefined) {
        validQuery = false
        endOfQuery = true
      }

      // const checkJoinCriteria = `Join-${queryCounter}` in query

      queryCounter++

      // Now test for the end of the query
      const checkNextFieldCriteria = `Field-${queryCounter}` in query
      const checkNextOperatorCriteria = `Query-${queryCounter}` in query
      const checkNextValueCriteria = `Value-${queryCounter}` in query

      if (!checkNextFieldCriteria && !checkNextOperatorCriteria && !checkNextValueCriteria) {
        endOfQuery = true
      }
    }

    return validQuery
  }

  const buildReport = async () => {
    // Only build the report if a valid query has been entered
    if (validateQuery()) {
      const queryArray = await buildQuery()

      const results = await getReport(queryArray)

      history.push({
        pathname: `/report/${submissionType}`,
        state: results
      })
    } else {
      NotificationService.warning('Please complete the query before continuing')
    }
  }

  const getReportViaQuickSearch = async (queryArray) => {

    const searchQuery = await buildQuery()
    // merge the query with the quick search
    queryArray = queryArray.concat(searchQuery)

    const results = await getReport(queryArray)

    history.push({
      pathname: `/report/${submissionType}`,
      state: results
    })
  }

  const getReport = async (queryArray) => {
    if (queryArray) {
      const body = {
        submissionType: (submissionType === 'medicalinterventionreport'
          ? 'medicalinterventionreport'
          : 'incidentreport'),
        query: queryArray,
        reports: reportsByType[submissionType],
        // TODO: Need different fields !
        // fields: 'id,user,organisation,submission,questionKey,questionText,response',
        // limit: 1000
      };

      const params = {
        orgId: activeOrg.id
      }

      const results = await ReportService.getReport(apiToken, params, body)

      return results
    }
  }

  // Basically componentDidMount
  useEffect(() => {
    let unmounted = false

    if (!unmounted) {
      getQuickSearches()
    }

    return () => { unmounted = true }
  }, [])

  return (
    <Box width='xlarge'>
      {/* Header */}
      <BreadcrumbBar
        path={<><Anchor href='/'>BehaviourSmart</Anchor><Text color='brand' size='xsmall'>{history.location.pathname}</Text></>}
      >
        {capitalize(props.reportTitle)} Report
      </BreadcrumbBar>

      {/* Quick Search */}
      {quickReportSearches &&
        <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small' flex='grow'>
          <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
            <Box>
              <Text size='xlarge'>Quick Search</Text>
            </Box>
            <Box alignSelf='center' direction='row'>
              <Select
                labelKey='name'
                name='quickSearch'
                onChange={({ value: nextValue }) => {
                  // console.log(quickReportSearches)
                  let selectedQuery = null
                  quickReportSearches.forEach((item) => {
                    if (item.name === nextValue) {
                      selectedQuery = item
                    }
                  })
                  // console.log('XXX', selectedQuery)
                  setFullSelectedQuickSearch(selectedQuery)
                  setQuickSearchValue(selectedQuery.query)
                }}
                options={quickReportSearches}
                // NOTE: Normally we'd use 'query' as the key here
                // but because that is an array it seems to mess
                // things up and when selected the item does not
                // display in the dropdown. So we'll use the 'name'
                // and search for the 'query' when selected
                valueKey={{ key: 'name', reduce: true }}
              />
              <Button
                disabled={!quickSearchSelected.length}
                label='Get Report >'
                margin={{ horizontal: 'xsmall' }}
                onClick={() => getReportViaQuickSearch(quickSearchSelected)}
                target='_self'
              />
              <Button
                disabled={!quickSearchSelected?.length}
                label='Delete'
                grey
                margin={{ horizontal: 'xsmall' }}
                onClick={() => removeQuickSearch(quickSearchSelected)}
                target='_self'
              />
            </Box>
          </Box>
        </Box>}

      {/* Main */}
      <Box background='white' direction='column' gap='small' round='small' flex='grow'>
        <Box
          gap='small'
          margin={{ horizontal: 'small' }}
          pad='small'
          round='small'
        >
          <Box direction='column' gap='small'>
            <Box direction='row-responsive' justify='between' pad={{ horizontal: 'none', vertical: 'small' }}>
              <Text margin={{ top: 'xsmall' }} size='large'>Search Criteria</Text>
              <Box alignSelf='center' direction='row'>
                {!getQuickSearchName &&
                  <Button
                    onClick={(  ) => {
                      if (validateQuery()) {
                        displayQuickSearchName(true)
                      }
                    }}
                    label={<Text><FontAwesomeIcon icon={['fal', 'cloud-upload']} /> Add to Quick Search</Text>}
                    margin={{ horizontal: 'xsmall' }} target='_self'
                  />}
                {getQuickSearchName &&
                  <Form
                    onSubmit={({ value: nextValue }) => {
                      submitQuickSearch(nextValue)
                    }}
                  >
                    <FormFieldRequired
                      direction='row'
                      label='Quick Search Name'
                      name='quickSearchName'
                      required
                    >
                      <TextInput
                        name='quickSearchName'
                        type='text'
                      />
                    </FormFieldRequired>

                    <Button type='submit' label='Save' primary />
                  </Form>}
              </Box>
            </Box>

            <Form
              //   validate='blur'
              onChange={nextValue => {
                // console.log(nextValue)

                setQuery(nextValue)
              }}
              onSubmit={({ value: nextValue }) => {
                // console.log(nextValue)
                buildReport(nextValue)
              }}
              value={query}
              // value={organisationDetails}
            >
              <Box direction='column' gap='xsmall' margin={{ bottom: 'small' }}>
                {buildCriteria()}
                {/* <Button icon={<AddCircle size='medium' />} label='Add Criteria' color='primary' onClick={() => addCriteria()} style={{ height: '35px' }} alignSelf='start' margin={{ bottom: 'small' }} /> */}
                <Button label={<Text><FontAwesomeIcon icon={['fal', 'plus-circle']} /> Add Criteria</Text>} color='primary' onClick={() => addCriteria()} style={{ height: '35px' }} alignSelf='start' margin={{ bottom: 'small' }} />
              </Box>

              <Divider color='primary' margin={{ top: 'none', bottom: 'small' }} />

              {/* <Box direction='column' gap='xsmall' margin={{ top: 'small' }}>
                {queryResults?.length &&
                  <ReportSearchResults
                    results={queryResults}
                  />}
                {!queryResults?.length && <Text color='#666'>- No results to display -</Text>}
              </Box> */}

              <Box direction='row' justify='between' margin={{ top: 'medium' }}>
                <Button label='< Back' onClick={() => goBack()} secondary />
                <Button type='submit' label='Get Report' primary />
              </Box>
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ReportSearch
