import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom'

// Grommet
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button.js'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid'
import Select from '../../components/simple/input/Select';
import TextInput from '../../components/simple/input/TextInput';
import Divider from '../../components/simple/divider/Divider.js'

import Text from '../../components/simple/text/Text'
import LocalStorageService from '../../services/LocalStorageService.js';
import AIService from '../../services/AIService';
import FormService from '../../services/FormService';
import UserService from '../../services/UserService';
import { lowercase } from '../../utils/text';
import { studentLabels, studentIncidentLabels, studentPostIncidentLabels, studentPreIncidentLabels } from '../../utils/aiPromptLabels.js';


// Shared Components
import BreadcrumbBar from '../shared/BreadcrumbBar'
import AILinkBar from './shared/AiLinkBar.js';

function AIStudent (props) {
  const history = useHistory()
  const activeOrg = LocalStorageService.get('activeOrg');
  const apiToken = LocalStorageService.get('apiToken');
  const terms = LocalStorageService.get('terms');

  const params = useParams();

  const userId = params.userid;
  const formId = params.formId;


  const [aiSystemMessage, setAiSystemMessage] = useState('');
  const [aiPrompt, setAiPrompt] = useState('');
  const [aiResponse, setAiResponse] = useState('');
  const [aiError, setAiError] = useState(false);

  const [selectedAction, setSelectedAction] = useState('');
  const [availableActions, setAvailableActions] = useState({});


  const [customPrompt, setCustomPrompt] = useState('');

  const [studentValues, setStudentValues] = useState([]);
  const [activeStudent, setActiveStudent] = useState(null);

  const getUsers = async (type = 'user') => {
      const params = {
        fields: 'id,firstName,lastName,reference,type',
        limit: 1000,
        orgId: activeOrg.id,
        type,
      };
  
    //   setLoading(true);
      const users = await UserService.getUsers(apiToken, params);
      if (!users || users?.error) {
        // NotificationService.error(users.error)
      } else {
        // if (!unmounted) {
        if (users?.error) {
            setStudentValues(null);
        } else if (users?.data) {
            var studentUsers = [];
            for (const thisUser of users.data) {
              if (thisUser.firstName && thisUser.lastName) {
                studentUsers.push({
                  id: thisUser.id,
                  learnerNumber: thisUser.reference,
                  name: thisUser.firstName + ' ' + thisUser.lastName,
                });
              }
            }
            studentUsers = studentUsers.sort((a, b) =>
              a.name.localeCompare(b.name),
            );
            setStudentValues(studentUsers);
        }
        // }
      }
    };

  useEffect(() => {
    
    let serviceUserTerm = lowercase(terms?.serviceUser || 'Service User')

    let newLabels = studentIncidentLabels
    // TEMPORARY
    newLabels['custom'] = '[DEVELOPMENT ONLY] Ask your own question'

    setAvailableActions(newLabels)
    
  }, [props.terms]);



  const requestInsight = async (action) => {

    if (!activeStudent?.id) {
        setAiResponse('')
        setAiError(true)
        return;
    }

    setSelectedAction(action)

    setAiPrompt('Thinking...')
    setAiResponse('Thinking...')
    setAiSystemMessage('Thinking...')

    let promptToSend = null;

    if (action === 'custom') { promptToSend = customPrompt }
    
    if (action === 'custom' && !promptToSend) {
        setAiResponse('')
        setAiError(true)
        return;
    }

    


    const response = await AIService.queryStudent(apiToken, activeStudent.id, action, promptToSend)

    if (response?.response) {
      setAiPrompt(response?.prompt)
      setAiResponse(response.response)
      setAiSystemMessage(response?.systemMessage)
      setAiError(false)
    } else {
      setAiResponse('')
      setAiError(true)
    }
  }

  useEffect(() => {

    getUsers('student');
    // if (formId) {
        // requestInsight('summarise')
    // }
  }, [formId])



  return (
    <>
      <BreadcrumbBar
        path={
          <>
            <Anchor href="/">BehaviourSmart</Anchor>
            <Text color="brand" size="xsmall">
              {history.location.pathname}
            </Text>
          </>
        }
      >
        AI Insights - Student
      </BreadcrumbBar>
            
      <AILinkBar />

      <Box
        background="white"
        direction="column"
        gap="small"
        margin={{ bottom: 'small' }}
        round="small"
      >
        <Box
          justify="between"
          pad={{ horizontal: 'medium', vertical: 'small' }}
        >
          <Text margin={{ top: 'xsmall', bottom: 'xsmall' }} size="xlarge">
            Disclaimer
          </Text>
          <Text color='secondary'>
            Our AI suggestions are for informational purposes only. Users should evaluate and apply these ideas based on their expertise. Behaviour Smart is not liable for outcomes from these suggestions. Always consult a qualified professional for personalised guidance. Please do not share data with parents or other parties that you're not authorised to.
            Please report inaccurate information or issues to <Anchor color='secondary' href="https://hello.behavioursmart.co.uk/ai-feedback" target="_blank">https://hello.behavioursmart.co.uk/ai-feedback</Anchor>
          </Text>
        </Box>
      </Box>


      <Box
        background="white"
        direction="column"
        gap="small"
        margin={{ bottom: 'small' }}
        round="small"
      >
        <Box
          justify="between"
          pad={{ horizontal: 'medium', vertical: 'small' }}
        >
          <Text margin={{ top: 'xsmall', bottom: 'xsmall' }} size="xlarge">
            Select {(terms?.serviceUser || 'service user')} 
          </Text>
           <Select
                onChange={({ option }) => setActiveStudent(option)}
                labelKey="name"
                placeholder={
                    'Select ' +
                    (terms?.serviceUser || 'service user')
                }
                options={studentValues}
                onSearch={text => {
                    // The line below escapes regular expression special characters:
                    // [ \ ^ $ . | ? * + ( )
                    const escapedText = text.replace(
                    /[-\\^$*+?.()|[\]{}]/g,
                    '\\$&',
                    );
                    // Create the regular expression with modified value which
                    // handles escaping special characters. Without escaping special
                    // characters, errors will appear in the console
                    const exp = new RegExp(escapedText, 'i');

                    setStudentValues(
                    props.studentValues.filter(o => exp.test(o.name)),
                    );
                }}
                valueKey={{ key: 'id', reduce: false }}
            />
        </Box>
      </Box>

      {selectedAction === 'custom' && activeStudent && <Box
        background="white"
        direction="column"
        gap="small"
        margin={{ bottom: 'small' }}
        round="small"
      >
        <Box
          justify="between"
          pad={{ horizontal: 'medium', vertical: 'small' }}
        >
          <Text margin={{ top: 'xsmall', bottom: 'xsmall' }} size="xlarge">
            Custom Prompt {customPrompt}
          </Text>

            <TextInput
                onChange={event => setCustomPrompt(event.target.value)}
                placeholder="Enter a prompt"
                type="text"
            />
            <Button onClick={() => requestInsight('custom')} label='Submit' primary />
        </Box>
      </Box>}

      {(aiResponse || aiError) && <Box
        background="white"
        direction="column"
        gap="small"
        margin={{ bottom: 'small' }}
        round="small"
      >
        <Box
          justify="between"
          pad={{ horizontal: 'medium', vertical: 'medium' }}
        >
        <Text size="large" style={{ width: '100%'}}>{availableActions[selectedAction]}</Text>

          <Text margin={{ top: 'xsmall' }} size="xlarge">
            {aiResponse && <>
                <Text style={{ whiteSpace: 'pre-line' }}>{aiResponse}</Text>
            </>}
            {aiError && <Text color='red'>There was an error processing your request. Please try again.</Text>}
          </Text>
        </Box>
      </Box>}

      {!activeStudent && <Box
        background="white"
        direction="column"
        gap="small"
        margin={{ bottom: 'small' }}
        round="small"
      >
        <Box
          justify="between"
          pad={{ horizontal: 'medium', vertical: 'small' }}
        >
          <Text color='primary'>
            Select a {(terms?.serviceUser || 'service user')?.toLowerCase()} to continue
          </Text>
        </Box>
      </Box>}


      {activeStudent && <Box margin={{ bottom: 'xlarge' }}>

      <Box background='white' direction='column' gap='small' round='small' flex='grow' margin={{ top: 'medium', bottom: 'medium' }}>
            <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
                <Text size='large'>
                  Pre Incident Support
                </Text>
            </Box>
        </Box>

      
      <ResponsiveGrid
        rows={['auto']}
        columns={{
          small: ['auto'],
          medium: ['33%', '33%', '33%'],
          large: ['33%', '33%', '33%'],
          xlarge: ['33%', '33%', '33%'],
        }}
        gap="small"
      >



        
          

          { Object.keys(studentPreIncidentLabels).map((key) => (
          <Box background={key === selectedAction ? 'primary' : "white" } margin="xsmall" pad="small" round="small">
            <Box height="50px" onClick={() => requestInsight(key)}>
              <Text weight="bold" textStyle={{ underline: 'none' }} textAlign="center" color={key === selectedAction ? 'white' : 'primary'}>
                  {studentPreIncidentLabels[key]}
              </Text>
            </Box>
          </Box>
          ))}


        </ResponsiveGrid>

      <Box background='white' direction='column' gap='small' round='small' flex='grow' margin={{ top: 'medium', bottom: 'medium' }}>
              <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
                  <Text size='large'>
                    Incident Support
                  </Text>
              </Box>
          </Box>

           
      <ResponsiveGrid
        rows={['auto']}
        columns={{
          small: ['auto'],
          medium: ['33%', '33%', '33%'],
          large: ['33%', '33%', '33%'],
          xlarge: ['33%', '33%', '33%'],
        }}
        gap="small"
      >




          { Object.keys(availableActions).map((key) => (
          <Box background={key === selectedAction ? 'primary' : "white" } margin="xsmall" pad="small" round="small">
            <Box height="50px" onClick={() => requestInsight(key)}>
              <Text weight="bold" textStyle={{ underline: 'none' }} textAlign="center" color={key === selectedAction ? 'white' : 'primary'}>
                  {availableActions[key]}
              </Text>
            </Box>
          </Box>
          ))}
        </ResponsiveGrid>


        <Box background='white' direction='column' gap='small' round='small' flex='grow' margin={{ top: 'medium', bottom: 'medium' }}>
        <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
                  <Text size='large'>
                    Post Incident Support
                  </Text>
              </Box>
          </Box>

          <ResponsiveGrid
        rows={['auto']}
        columns={{
          small: ['auto'],
          medium: ['33%', '33%', '33%'],
          large: ['33%', '33%', '33%'],
          xlarge: ['33%', '33%', '33%'],
        }}
        gap="small"
      >


          { Object.keys(studentPostIncidentLabels).map((key) => (
          <Box background={key === selectedAction ? 'primary' : "white" } margin="xsmall" pad="small" round="small">
            <Box height="50px" onClick={() => requestInsight(key)}>
              <Text weight="bold" textStyle={{ underline: 'none' }} textAlign="center" color={key === selectedAction ? 'white' : 'primary'}>
                  {studentPostIncidentLabels[key]}
              </Text>
            </Box>
          </Box>
          ))}


        </ResponsiveGrid>
      </Box>}

     

      {(aiResponse || aiError) && <Box
            background="white"
            direction="column"
            gap="small"
            margin={{ bottom: 'small' }}
            round="small"
        >

            <Box direction='column' gap='xsmall' margin={{ top: 'small', bottom: 'medium', horizontal: 'medium' }}>
                <Text color='brand' size='large'>DEVELOPER DEBUG</Text>
                {aiResponse && <>
                    <Text weight='bold'>System Message</Text>
                    <Text style={{ whiteSpace: 'pre-line', marginBottom: 20 }}>{aiSystemMessage}</Text>
                    <Text weight='bold'>Prompt</Text>
                    <Text style={{ whiteSpace: 'pre-line', marginBottom: 20 }}>{aiPrompt}</Text>
                    <Text weight='bold'>Response</Text>
                    <Text style={{ whiteSpace: 'pre-line' }}>{aiResponse}</Text>
                </>}
                {aiError && <Text color='red'>There was an error processing your request. Please try again.</Text>}
            </Box>
        </Box>}
    </>
  );
}

export default AIStudent
