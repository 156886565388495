import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor';
import Box from '../../components/simple/box/Box';
import Button from '../../components/simple/button/Button';
import Divider from '../../components/simple/divider/Divider.js';
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome';
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid';
import Text from '../../components/simple/text/Text';
import ReactToPrint from 'react-to-print';

// App widgets
import SimpleCounter from '../../components/compound/simpleCounter/SimpleCounter';

// Shared Components
import BreadcrumbBar from '../shared/BreadcrumbBar';

import RelatedIncidents from './common/RelatedIncidents.js';

import { Bar, Doughnut, Line, Polar } from 'react-chartjs-2';

import LocalStorageService from '../../services/LocalStorageService';
import { capitalize } from '../../utils/text';
import { backgroundColours, borderColours } from './colours.js';
import { use } from 'react';


const terms = LocalStorageService.get('terms');

const MedicalInterventionReport = props => {
  const history = useHistory();

  const [
    numIncidentsInvolvingInjuryToChild,
    setNumIncidentsInvolvingInjuryToChild,
  ] = useState(0);

  const [
    numIncidentsInvolvingInjuryToStaff,
    setNumIncidentsInvolvingInjuryToStaff,
  ] = useState(0);

  const [
    numIncidentsInvolvingInjuryToOthers,
    setNumIncidentsInvolvingInjuryToOthers,
  ] = useState(0);

  const [
    numIncidentsRestrictedInterventionUsed,
    setNumIncidentsRestrictedInterventionUsed,
  ] = useState(0);

  const [
    totalNumberOfIncidentsByStudentGroup,
    setTotalNumberOfIncidentsByStudentGroup,
  ] = useState([]);

  const [
    totalNumberOfIncidentsByDate,
    setTotalNumberOfIncidentsByDate,
  ] = useState([]);

  const [
    totalNumberOfIncidentsByHour,
    setTotalNumberOfIncidentsByHour,
  ] = useState([]);
  const [
    averageNumberOfStaffPerIncidentByLocation,
    setAverageNumberOfStaffPerIncidentByLocation,
  ] = useState([]);

  const [
    otherChildrenInvolvedInIncident,
    setOtherChildrenInvolvedInIncident,
  ] = useState([]);

  const [relatedIncidents, setRelatedIncidents] = useState([]);

  const terms = LocalStorageService.get('terms');

  // Incidents by Student Group
  const studentgroupData = {
    labels: totalNumberOfIncidentsByStudentGroup[1],
    datasets: [
      {
        // label: '# of Votes',
        data: totalNumberOfIncidentsByStudentGroup[0],
        backgroundColor: backgroundColours,
        borderColor: borderColours,
        borderWidth: 1,
      },
    ],
  };

  // Incidents by Other Children
  const otherChildrenData = {
    labels: otherChildrenInvolvedInIncident[1],
    datasets: [
      {
        // label: '# of Votes',
        data: otherChildrenInvolvedInIncident[0],
        backgroundColor: backgroundColours,
        borderColor: borderColours,
        borderWidth: 1,
      },
    ],
  };

  // Incidents by Date
  const incidentsByDate = {
    labels: totalNumberOfIncidentsByDate[1],
    datasets: [
      {
        // label: '# of Incidents',
        data: totalNumberOfIncidentsByDate[0],
        backgroundColor: backgroundColours,
        borderColor: borderColours,
        borderWidth: 1,
      },
    ],
  };

  // Incidents by hour
  const incidentsByHour = {
    labels: totalNumberOfIncidentsByHour[1],
    datasets: [
      {
        // label: '# of Incidents',
        data: totalNumberOfIncidentsByHour[0],
        backgroundColor: backgroundColours,
        borderColor: borderColours,
        borderWidth: 1,
      },
    ],
  };

  // Location
  const staffByLocation = {
    labels: averageNumberOfStaffPerIncidentByLocation[1],
    datasets: [
      {
        // label: '# of Votes',
        data: averageNumberOfStaffPerIncidentByLocation[0],
        backgroundColor: backgroundColours,
        borderColor: borderColours,
        borderWidth: 1,
      },
    ],
  };

  const printScreen = () => {
    window.print();
  };

  useEffect(() => {
    // Map the API data in props.location.state into the relevant datasets

    // Child injuries
    if (props?.location?.state?.numIncidentsInvolvingInjuryToChild !== null) {
      const numIncidentsInvolvingInjuryToChild =
        props?.location?.state?.numIncidentsInvolvingInjuryToChild;

      setNumIncidentsInvolvingInjuryToChild(numIncidentsInvolvingInjuryToChild);
    }

    // Staff injuries
    if (props?.location?.state?.numIncidentsInvolvingInjuryToStaff !== null) {
      const numIncidentsInvolvingInjuryToStaff =
        props?.location?.state?.numIncidentsInvolvingInjuryToStaff;

      setNumIncidentsInvolvingInjuryToStaff(numIncidentsInvolvingInjuryToStaff);
    }

    // Other injuries
    if (props?.location?.state?.numIncidentsInvolvingInjuryToOthers !== null) {
      const numIncidentsInvolvingInjuryToOthers =
        props?.location?.state?.numIncidentsInvolvingInjuryToOthers;

      setNumIncidentsInvolvingInjuryToOthers(
        numIncidentsInvolvingInjuryToOthers,
      );
    }

    // Restricted Physical Intervension
    if (
      props?.location?.state?.numberIncidentsRestrictedInterventionUsed !== null
    ) {
      setNumIncidentsRestrictedInterventionUsed(
        props?.location?.state?.numberIncidentsRestrictedInterventionUsed,
      );
    }

    // Other Children
    if (props?.location?.state?.otherChildrenInvolvedInIncident !== null) {
      const otherChildrenInvolvedInIncident =
        props?.location?.state?.otherChildrenInvolvedInIncident;

      // Remove dupicates and add counts
      if (otherChildrenInvolvedInIncident) {
        const childrenCount = otherChildrenInvolvedInIncident.reduce(
          (newArray, child) => {
            const str = JSON.stringify(child);
            newArray[str] = (newArray[str] || 0) + 1;
            return newArray;
          },
          {},
        );

        const otherChildrenInvolvedInIncidentLabels = [];
        const otherChildrenInvolvedInIncidentData = [];

        for (const x in childrenCount) {
          otherChildrenInvolvedInIncidentLabels.push(JSON.parse(x).name);
          otherChildrenInvolvedInIncidentData.push(childrenCount[x]);
        }
        setOtherChildrenInvolvedInIncident([
          otherChildrenInvolvedInIncidentData,
          otherChildrenInvolvedInIncidentLabels,
        ]);
      }
    }

    // Student Group
    const totalNumberOfIncidentsByStudentGroup =
      props?.location?.state?.numIncidentsInvolvingInjuryByStudentGroup;

    if (totalNumberOfIncidentsByStudentGroup) {
      const totalNumberOfIncidentsByStudentGroupLabels = totalNumberOfIncidentsByStudentGroup.map(
        (data, index) => {
          return data.userGroup;
        },
      );

      const totalNumberOfIncidentsByStudentGroupData = totalNumberOfIncidentsByStudentGroup.map(
        (data, index) => {
          return data.count;
        },
      );

      setTotalNumberOfIncidentsByStudentGroup([
        totalNumberOfIncidentsByStudentGroupData,
        totalNumberOfIncidentsByStudentGroupLabels,
      ]);
    }

    // Date
    const totalNumberOfIncidentsByDate =
      props?.location?.state?.totalNumberOfIncidentsByDate;

    if (totalNumberOfIncidentsByDate) {
      const totalNumberOfIncidentsByDateLabels = totalNumberOfIncidentsByDate.map(
        (data, index) => {
          return data.month || 'n/a';
        },
      );

      const totalNumberOfIncidentsByDateData = totalNumberOfIncidentsByDate.map(
        (data, index) => {
          return data.count;
        },
      );

      setTotalNumberOfIncidentsByDate([
        totalNumberOfIncidentsByDateData,
        totalNumberOfIncidentsByDateLabels,
      ]);
    }

    // Time
    const totalNumberOfIncidentsByHour =
      props?.location?.state?.totalNumberOfIncidentsByHour;

    if (totalNumberOfIncidentsByHour) {
      const totalNumberOfIncidentsByHourLabels = totalNumberOfIncidentsByHour.map(
        (data, index) => {
          return data.hour || 'n/a';
        },
      );

      const totalNumberOfIncidentsByHourData = totalNumberOfIncidentsByHour.map(
        (data, index) => {
          return data.count;
        },
      );

      setTotalNumberOfIncidentsByHour([
        totalNumberOfIncidentsByHourData,
        totalNumberOfIncidentsByHourLabels,
      ]);
    }

    // Location
    if (
      props?.location?.state?.averageNumberOfStaffPerIncidentByLocation !== null
    ) {
      const averageNumberOfStaffPerIncidentByLocation =
        props?.location?.state?.averageNumberOfStaffPerIncidentByLocation;

      const averageNumberOfStaffPerIncidentByLocationLabels = [];
      const averageNumberOfStaffPerIncidentByLocationData = [];

      for (var x in averageNumberOfStaffPerIncidentByLocation) {
        if (x === 'null') {
          x = null;
        }
        averageNumberOfStaffPerIncidentByLocationLabels.push(x || 'n/a');
        averageNumberOfStaffPerIncidentByLocationData.push(
          averageNumberOfStaffPerIncidentByLocation?.[x]?.numIncidents || 0,
        );
      }

      setAverageNumberOfStaffPerIncidentByLocation([
        averageNumberOfStaffPerIncidentByLocationData,
        averageNumberOfStaffPerIncidentByLocationLabels,
      ]);
    }

    if (props?.location?.state?.incidentSearch !== null) {
      setRelatedIncidents(props.location.state.incidentSearch);
    }

      

  }, []);

  let componentRef = null;
  let setComponentRef = ref => {
    componentRef = ref;
  };

  let reactToPrintContent = () => {
    return componentRef;
  };
  const reactToPrintTrigger = () => {
    return (
      <Button
        label={<FontAwesome icon={['fal', 'print']} size="xs" />}
        className="noprint"
        plain
        target="_self"
      />
    );
  };

  return (
    <>
      <BreadcrumbBar
        path={
          <>
            <Anchor href="/">BehaviourSmart</Anchor>
            <Text color="brand" size="xsmall">
              {history.location.pathname}
            </Text>
          </>
        }
      >
        <Box direction="row" justify="between">
          <Text>Medical Intervention Report</Text>

          <ReactToPrint
            content={reactToPrintContent}
            documentTitle="AwesomeFileName"
            removeAfterPrint
            trigger={reactToPrintTrigger}
          />
        </Box>
      </BreadcrumbBar>

      <Box ref={setComponentRef} direction="column" gap="small" round="small">
        <ResponsiveGrid
          rows={['auto']}
          columns={{
            small: ['auto'],
            medium: ['auto', 'auto', 'auto', 'auto'],
            large: ['auto', 'auto', 'auto', 'auto'],
            xlarge: ['auto', 'auto', 'auto', 'auto'],
          }}
          gap="small"
        >
          <SimpleCounter
            count={String(numIncidentsInvolvingInjuryToChild || 0)}
            label={
              terms?.serviceUser
                ? `Incidents involving injury to ${
                    terms.serviceUser?.charAt(0)?.toUpperCase() +
                    terms?.serviceUser.slice(1)
                  }`
                : 'Incidents involving injury to Student'
            }
          />
          <SimpleCounter
            count={String(numIncidentsInvolvingInjuryToStaff || 0)}
            label="Incidents involving injury to staff member"
          />
          <SimpleCounter
            count={String(numIncidentsInvolvingInjuryToOthers || 0)}
            label="Incidents involving injury to other"
          />
        </ResponsiveGrid>
      </Box>
      <RelatedIncidents results={relatedIncidents} />
    </>
  );
};

export default MedicalInterventionReport;
