import React, { useEffect, useState } from 'react';

import { useHistory, useParams } from 'react-router-dom'

// Grommet
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button.js'
import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid'
import Text from '../../components/simple/text/Text'
import LocalStorageService from '../../services/LocalStorageService.js';
import AIService from '../../services/AIService';
import FormService from '../../services/FormService';
import { lowercase } from '../../utils/text';


// Shared Components
import BreadcrumbBar from '../shared/BreadcrumbBar'
import AILinkBar from './shared/AiLinkBar.js';

function AIIncident (props) {
  const history = useHistory()
  const activeOrg = LocalStorageService.get('activeOrg');
  const apiToken = LocalStorageService.get('apiToken');
  const terms = LocalStorageService.get('terms');

  const params = useParams();

  const userId = params.userid;
  const formId = params.formId;


  const [aiSystemMessage, setAiSystemMessage] = useState('');
  const [aiPrompt, setAiPrompt] = useState('');
  const [aiResponse, setAiResponse] = useState('');
  const [aiError, setAiError] = useState(false);

  const [selectedAction, setSelectedAction] = useState('summarise');
  const [availableActions, setAvailableActions] = useState({});


  useEffect(() => {
    
    let serviceUserTerm = lowercase(terms?.serviceUser || 'Service User')

    setAvailableActions({
        'summarise': 'Summarise Report',
        // 'lettertoparents': 'Letter to parents',
        'suggestions': 'Recommend Actions',
        'assess': 'Assess the incident report.',
        'informalletter':  'Draft an informal letter/email',
        'formalletter':  'Draft a formal letter',
        'suggeststaffstrategies':  'Suggest strategies for staff ',
        'suggestindividualstrategies':  'Suggest strategies for the ' + serviceUserTerm,
        'supportindividual': 'How can we best support this ' + serviceUserTerm,
        'moredetailonsuggestedstrategies': 'Give more detail on the suggested strategies',
        'postincidentlearning': 'Help me with post incident learning',
        'whatcanwelearn': 'What can we learn',
        'suggestworkshopideas': 'Suggest workshop ideas',
        'suggestlowlevelresponses': 'Suggest other response to low level behaviours',
        'suggestmediumlevelresponses': 'Suggest other response to medium level behaviours',
        'suggesthighlevelresponses': 'Suggest other response to high level behaviours',
        'possiblefunctions': 'Possible functions of behaviour'
      })
    
  }, [props.terms]);



  const requestIncidentAI = async (action) => {

    setSelectedAction(action)

    setAiPrompt('Thinking...')
    setAiResponse('Thinking...')
    setAiSystemMessage('Thinking...')

    const response = await AIService.queryIncident(apiToken, userId, formId, action)

    if (response?.response) {
      setAiPrompt(response?.prompt)
      setAiResponse(response.response)
      setAiSystemMessage(response?.systemMessage)
      setAiError(false)
    } else {
      setAiResponse('')
      setAiError(true)
    }
  }

  useEffect(() => {
    if (formId) {
        requestIncidentAI('summarise')
    }
  }, [formId])



  return (
    <>
      <BreadcrumbBar
        path={
          <>
            <Anchor href="/">BehaviourSmart</Anchor>
            <Text color="brand" size="xsmall">
              {history.location.pathname}
            </Text>
          </>
        }
      >
        [BETA] BehaviourSmart AI
      </BreadcrumbBar>

      <AILinkBar />
    
      <Box
        background="white"
        direction="column"
        gap="small"
        margin={{ bottom: 'small' }}
        round="small"
      >
        <Box
          justify="between"
          pad={{ horizontal: 'medium', vertical: 'small' }}
        >
          <Text margin={{ top: 'xsmall', bottom: 'xsmall' }} size="xlarge">
            Disclaimer
          </Text>
          <Text color='secondary'>
            Our AI suggestions are for informational purposes only. Users should evaluate and apply these ideas based on their expertise. Behaviour Smart is not liable for outcomes from these suggestions. Always consult a qualified professional for personalised guidance. Please do not share data with parents or other parties that you're not authorised to.
            Please report inaccurate information or issues to <Anchor color='secondary' href="https://hello.behavioursmart.co.uk/ai-feedback" target="_blank">https://hello.behavioursmart.co.uk/ai-feedback</Anchor>
          </Text>
        </Box>
      </Box>

      {(aiResponse || aiError) && <Box
        background="white"
        direction="column"
        gap="small"
        margin={{ bottom: 'small' }}
        round="small"
      >
        <Box
          justify="between"
          pad={{ horizontal: 'medium', vertical: 'medium' }}
        >
        <Text size="large" style={{ width: '100%'}}>{availableActions[selectedAction]}</Text>

          <Text margin={{ top: 'xsmall' }} size="xlarge">
            {aiResponse && <>
                <Text style={{ whiteSpace: 'pre-line' }}>{aiResponse}</Text>
            </>}
            {aiError && <Text color='red'>There was an error processing your request. Please try again.</Text>}
          </Text>
        </Box>
      </Box>}

      <ResponsiveGrid
        rows={['auto']}
        columns={{
          small: ['auto'],
          medium: ['33%', '33%', '33%'],
          large: ['33%', '33%', '33%'],
          xlarge: ['33%', '33%', '33%'],
        }}
        gap="small"
      >

        { Object.keys(availableActions).map((key) => (
        <Box background={key === selectedAction ? 'primary' : "white" } margin="xsmall" pad="small" round="small">
          <Box height="50px" onClick={() => requestIncidentAI(key)}>
            <Text weight="bold" textStyle={{ underline: 'none' }} textAlign="center" color={key === selectedAction ? 'white' : 'primary'}>
                {availableActions[key]}
            </Text>
          </Box>
        </Box>
        ))}
      </ResponsiveGrid>
    </>
  );
}

export default AIIncident
