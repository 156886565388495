import React, { createContext, useEffect, useState } from 'react';

// Notifications
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Grommet
import { Grommet, ResponsiveContext } from 'grommet';

// Appt router
import PrivateRoute from './PrivateRoute';

// Appt Components
import Box from './components/simple/box/Box';

// Appt Screens
import BehaviourDrafts from './screens/behaviourPlan/BehaviourDrafts';
import BehaviourPlan from './screens/behaviourPlan/BehaviourPlan';

import MyLogin from './screens/MyLogin';
import ContentEdit from './screens/ContentEdit';
import ContentList from './screens/ContentList';
import Dashboard from './screens/Dashboard';
import ConcernEdit from './screens/concernEdit/ConcernEdit';
import IncidentEdit from './screens/incidentEdit/IncidentEdit';
import IncidentHighLevelEdit from './screens/incidentHighLevelEdit/IncidentHighLevelEdit';
import IncidentEditCompletion from './screens/incidentEdit/IncidentEdit-Completion';
import IncidentReport from './screens/reports/IncidentReport';
import InterventionReport from './screens/reports/InterventionReport';
import InvolvementReport from './screens/reports/InvolvementReport';
import PILExtrasReport from './screens/reports/PILExtrasReport';
import IncidentDrafts from './screens/search/incidentSearch/IncidentDrafts';
import IncidentSearch from './screens/search/incidentSearch/IncidentSearch';
import IncidentSearchGlobal from './screens/search/incidentSearch/IncidentSearchGlobal';
import AIIncidentTools from './screens/ai/AIIncidentTools';
import AIIncidentAlpha from './screens/ai/AIIncidentAlpha';
import AIIncident from './screens/ai/AIIncident';
import AIStudent from './screens/ai/AIStudent';
import AIService from './screens/ai/AIService';
import AILanding from './screens/ai/AILanding';
import Record from './screens/Record';

// import BehaviourPlan from './screens/behaviourPlan/BehaviourPlan'
import PasswordReset from './components/compound/login/PasswordReset';
import LoginScreen from './screens/Login';
import Logout from './screens/Logout';
import OrganisationEdit from './screens/OrganisationEdit';
import OrganisationList from './screens/OrganisationList';
import Privacy from './screens/Privacy';
import RoleEdit from './screens/RoleEdit';
import RoleList from './screens/RoleList';
import ServiceSetup from './screens/ServiceSetup';
import ServiceSubscription from './screens/ServiceSubscription';
import TandC from './screens/TandC';
import UserActivate from './screens/User/UserActivate';
import UserEdit from './screens/User/UserEdit';
import UserList from './screens/User/UserList';
import UserSignup from './screens/User/UserSignup';
import UserUpload from './screens/User/UserUpload';
import MedicalInterventionReport from './screens/reports/MedicalInterventionReport';
import Reports from './screens/reports/Reports';
import SmartPlans from './screens/behaviourPlan/SmartPlans';
import ReportSearch from './screens/search/reportSearch/ReportSearch';
import ServiceUserEdit from './screens/serviceUser/ServiceUserEdit';
import ServiceUserList from './screens/serviceUser/ServiceUserList';
import ServiceUserProfile from './screens/serviceUser/ServiceUserProfile';
import TermsService from './services/TermsService';

// Appt Menu
import Sidebar from './components/compound/sidebar/Sidebar';

// Stores
import { useStore, withStore } from 'react-context-hook';

// Services
import LocalStorageService from './services/LocalStorageService';
import NotificationService from './services/NotificationService';
import OrganisationService from './services/OrganisationService';
import PasswordService from './services/PasswordService';

// Utilities
import { isCurrentOrgAdmin, isGlobalAdmin } from './utils/permissions';

// Stripe
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

// Theme
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faChartPie as falChartPie,
  faRobot as falRobot,
  faClipboard as falClipboard,
  faClipboardListCheck as falClipboardListCheck,
  faCloudUpload as falCloudUpload,
  faCog as falCog,
  faDownload as falDownload,
  faEdit as falEdit,
  faEnvelope as falEnvelope,
  faExclamationCircle as falExclamationCircle,
  faExclamationTriangle as falExclamationTriangle,
  faEye as falEye,
  faFileAlt as falFileAlt,
  faFolderOpen as falFolderOpen,
  faHome as falHome,
  faInfoCircle as falInfoCircle,
  faKey as falKey,
  faMinusCircle as falMinusCircle,
  faPencil as falPencil,
  faPlusCircle as falPlusCircle,
  faPrint as falPrint,
  faQuestionCircle as falQuestionCircle,
  faSave as falSave,
  faSearch as falSearch,
  faTrash as falTrash,
  faUser as falUser,
  faUserCircle as falUserCircle,
  faClock as falClock,
  faBolt as falBolt,
} from '@fortawesome/pro-light-svg-icons';
import {
  faCog,
  faRobot,
  faExclamationCircle,
  faExclamationTriangle,
  faFile,
  faFilePdf,
  faFolderOpen,
  faGavel,
  faGraduationCap,
  faHome,
  faInfoCircle,
  faLink,
  faMusic,
  faNewspaper,
  faPlus,
  faPlusSquare,
  faQuestion,
  faQuestionCircle,
  faSave,
  faThLarge,
  faUpload,
  faUser,
  faUserFriends,
  faVideo,
  faFlag,
  faFlagAlt,
  faClock
} from '@fortawesome/pro-solid-svg-icons';
import { beSmartTheme } from './utils/beSmartTheme';

// Router
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
  // Link
} from 'react-router-dom';

// Error logging 
import { Provider, ErrorBoundary } from '@rollbar/react';

const rollbarConfig = {
  accessToken: '657a787429c84629993f3e0926300729',
  captureUncaught: true,
  captureUnhandledRejections: true
};

export const GlobalContext = createContext();

function App() {
  library.add(
    falCog,
    falPencil,
    falRobot,
    falInfoCircle,
    falHome,
    falSave,
    falUser,
    falClipboard,
    falClipboardListCheck,
    falFolderOpen,
    falUserCircle,
    falExclamationCircle,
    falExclamationTriangle,
    falSearch,
    falChartPie,
    falPlusCircle,
    falMinusCircle,
    falQuestionCircle,
    falCloudUpload,
    falKey,
    falTrash,
    falEdit,
    falEye,
    falFileAlt,
    falDownload,
    falEnvelope,
    faLink,
    falPrint,
    falClock,
    falBolt
  );
  library.add(
    faCog,
    faInfoCircle,
    faExclamationCircle,
    faExclamationTriangle,
    faGraduationCap,
    faHome,
    faSave,
    faUser,
    faRobot,
    faUserFriends,
    faPlusSquare,
    faQuestion,
    faQuestionCircle,
    faVideo,
    faFolderOpen,
    faMusic,
    faNewspaper,
    faFilePdf,
    faFile,
    faUpload,
    faPlus,
    faThLarge,
    faGavel,
    faFlag,
    faFlagAlt,
    faClock
  );

  const history = useHistory();

  const [apiToken, setApiToken, deleteApiToken] = useStore();
  const [currentUser, setCurrentUser, deleteCurrentUser] = useStore({});
  const [currentOrgs, setCurrentOrgs, deleteCurrentOrgs] = useStore([]);
  const [activeOrg, setActiveOrg, deleteActiveOrg] = useStore({});
  const [terms, setTerms, deleteTerms] = useStore({});

  const [isOrgAdmin, setIsOrgAdmin] = useState(false);

  const [activeOrgState, setActiveOrgState] = useState([]);
  const [myOrganisations, setMyOrganisations] = useState([]);
  const [myPermissions, setMyPermissions] = useState([]);
  const [loginState, setLoginState] = useState({
    loggedInStatus: false,
    apiToken: '',
    userOrganisations: [],
    userDetails: {},
  });

  const stripePromise = loadStripe(
    'pk_test_51I9vWmJcjjdzjSkJ99F4QxWzx9tnSntqhPzsm3Lxct69p2KTXR8YnpFbYqw7tll1SMqBOUZT1nw9VEgYXpPpElQd00EH1uUfxf',
  );


  const refreshOrgData = async () => {
    const activeOrg = LocalStorageService.get('activeOrg')

    if (activeOrg?.id) {
      const response = await OrganisationService.getOrganisation(apiToken, activeOrg.id);
      if (response?.data?.length) {
        let refreshedData = {
          ...activeOrg,
          ...response.data[0]
        }
        if (!response.data[0]?.aiTermsAccepted) delete refreshedData.aiTermsAccepted
        if (!response.data[0]?.enablePILExtras) delete refreshedData.enablePILExtras
        if (!response.data[0]?.wondeSync) delete refreshedData.wondeSync
        LocalStorageService.set('activeOrg', refreshedData)
        setActiveOrg(refreshedData);
      }
    }
  }

  const checkLoginStatus = () => {
    // If no apiToken in global store get from local storage
    if (!apiToken) {
      const localApiToken = LocalStorageService.get('apiToken');

      if (localApiToken) {
        setApiToken(localApiToken);
        setCurrentOrgs(LocalStorageService.get('userOrganisations'));
        setCurrentUser(LocalStorageService.get('userDetails'));
        setActiveOrg(LocalStorageService.get('activeOrg'));
        console.log('checkLoginStatus ', LocalStorageService.get('activeOrg'));
        setActiveOrgState(LocalStorageService.get('activeOrg'));
        setMyPermissions(LocalStorageService.get('myPermissions'));
        refreshOrgData()
      } else {
        clearSession();
        // window.location.reload()
      }
    }

    // always set the terms
    setTerms(LocalStorageService.get('terms'));

    // If no apiToken in local storage either,
    // clear out the session just in case and
    // force a login
    // if (!apiToken) {
    //   // setApiToken(apiToken)
    //   // get currentOrgs
    //   // const myOrganisations = LocalStorageService.get('currentOrgs')
    //   // setCurrentOrgs(myOrganisations)
    // // } else {
    //   clearSession()
    // }
  };

  // clear the user's session if they log out
  const clearSession = () => {
    // remove all localstorage
    LocalStorageService.clear();

    // remove the session data
    deleteApiToken();
    deleteCurrentUser();
    deleteCurrentOrgs();
    deleteActiveOrg();
    deleteTerms();
    // history.push('/')
  };
  // Get system tags to handle common terms
  const getCommonTerms = async () => {
    const matchedTerms = await TermsService.getCommonTerms();

    LocalStorageService.set('terms', matchedTerms);
    setTerms(matchedTerms);
  };

  const handleLogin = data => {
    // Set state
    data.loggedInStatus = true;
    setLoginState(data);

    // And set in local storage & global store
    LocalStorageService.set('apiToken', data.apiToken);
    setApiToken(data.apiToken);
    LocalStorageService.set('userOrganisations', data.userOrganisations);
    setCurrentOrgs(data.userOrganisations);
    LocalStorageService.set('activeOrg', data.userOrganisations[0]);
    setActiveOrg(data.userOrganisations[0]);
    console.log('handleLogin', data.userOrganisations[0]);
    setActiveOrgState(data.userOrganisations[0]);
    LocalStorageService.set('userDetails', data.userDetails);
    setCurrentUser(data.userDetails);

    getCommonTerms(data.apiToken);

    // store the permissions for easy access
    var myPermissions = [];
    if (data?.userOrganisations?.[0]?.permissions) {
      myPermissions = data.userOrganisations[0].permissions;
    }
    LocalStorageService.set('myPermissions', myPermissions);
    setMyPermissions(myPermissions);

    // is the org admin me?
    if (data?.userOrganisations?.[0]?.admin && data?.userDetails?.id) {
      if (
        data.userDetails.id === 1 ||
        data.userDetails.isglobaladmin ||
        data.userDetails.isdeveloper
      ) {
        setIsOrgAdmin(true);
      } else {
        setIsOrgAdmin(data.userOrganisations[0].admin === data.userDetails.id);
      }
    }
  };

  const handleLogout = () => {
    setLoginState({
      loggedInStatus: false,
      apiToken: '',
      userOrganisations: [],
      userDetails: {},
    });
    clearSession();
  };

  const handlePasswordReset = async (token, data) => {
    console.log(data, token);
    try {
      var passwordResetStatus = await PasswordService.resetPassword(
        token,
        data.password,
      );
    } catch (err) {
      NotificationService.error(err);
    }

    NotificationService.info('Your password has been reset');

    // history doesn't work in App.js, we'll use this as a test case
    window.location.href = '/login';
  };

  // Set local storage etc with the active organisation
  const selectActiveOrg = org => {
    LocalStorageService.set(
      'activeOrg',
      currentOrgs.find(x => x.id === org),
    );
    setActiveOrg(currentOrgs.find(x => x.id === org));
    console.log(
      'selectActiveOrg ',
      currentOrgs.find(x => x.id === org),
    );
    setActiveOrgState(currentOrgs.find(x => x.id === org));
    // Why does this not work ?
    // history.push('/')
  };

  // const apiToken = LocalStorageService.get('apiToken')

  // Get Organisation for this User
  const getMyOrganisations = async apiToken => {
    if (apiToken) {
      const myOrganisations = await OrganisationService.myOrganisations(
        apiToken,
      );

      setMyOrganisations(myOrganisations);
    }
  };

  // Basically componentDidMount
  // useEffect(() => {
  //   getMyOrganisations(apiToken)
  // }, [apiToken])
  useEffect(() => {
    console.log('App.js fired');
    checkLoginStatus();
  }, []);

  useEffect(() => {
    var menuSetup = [];
    const org = LocalStorageService.get('activeOrg');
    const user = LocalStorageService.get('userDetails');

    menuSetup.push({
      linkHref: '/dashboard',
      icon: 'home',
      linkTitle: 'Dashboard ',
    });


    // Students 
    menuSetup.push({
      linkHref: '/record',
      icon: 'plus-circle',
      linkTitle: 'Record',
    });

    menuSetup.push({
      linkHref: '/serviceusers',
      icon: 'folder-open',
      linkTitle: 'Directory',
    });

    // Incidents
    if (org?.safeguardingLeads?.includes(user?.email?.toLocaleLowerCase())) {
      menuSetup.push({
        linkHref: '/incident/search/global',
        icon: 'exclamation-circle',
        linkTitle: 'Incidents',
      });
    } else { 
      menuSetup.push({
        linkHref: '/incident/search',
        icon: 'exclamation-circle',
        linkTitle: 'Incidents',
      });
    }


    // Plans
    if (activeOrg?.plan === 'Plus') {
      menuSetup.push({
        linkHref: '/plans',
        icon: 'clipboard-list-check',
        linkTitle: 'Smart Plans',
      });
    }

    // Reports

    // if (isGlobalAdmin()) {
    //   menuSetup.push({
    //     linkHref: '/incident/ai',
    //     icon: 'robot',
    //     linkTitle: '(DEV) AI Testing',
    //   });
    // }
    if (org?.aiTermsAccepted || isGlobalAdmin()) {

      menuSetup.push({
        linkHref: '/ai/insights',
        icon: 'robot',
        linkTitle: 'AI Insights',
      });
    }

    if (org?.safeguardingLeads?.includes(user?.email?.toLocaleLowerCase())) {
      menuSetup.push({
        linkHref: '/reports',
        icon: 'chart-pie',
        linkTitle: 'Analytics',
      });
    }

    // Settings
    
    if (
      isCurrentOrgAdmin() ||
      org?.safeguardingLeads?.includes(user?.email?.toLocaleLowerCase())
    ) {
      menuSetup.push({
        linkHref: '/users',
        icon: 'user-circle',
        linkTitle: 'System Users Page',
      });
    }

    if (isCurrentOrgAdmin() || isOrgAdmin) {
      menuSetup.push({
        linkHref: '/servicesetup',
        icon: 'cog',
        linkTitle: serviceSetupTitle,
      });
    }

    setMenuState(menuSetup);
  }, [activeOrg, myPermissions]);

  const serviceSetupTitle = `${
    terms && terms.service
      ? terms.service?.charAt(0)?.toUpperCase() + terms.service.slice(1)
      : 'Service'
  } Setup`;

  const [menuState, setMenuState] = useState([]);

  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <GlobalContext.Provider value={{ menuState, setMenuState }}>
          <Router>
            <Grommet
              background={{
                color: 'background',
                image: 'url(/Behaviour-Smart-Background-2.png)',
              }}
              full
              theme={beSmartTheme}
            >
              <ToastContainer position={toast.POSITION.TOP_RIGHT} />

              <ResponsiveContext.Consumer>
                {size => {
                  return (
                    <Box
                      className="pageWrapper"
                      align="center"
                      width="100%"
                      height={size !== 'small' ? '100%' : null}
                    >
                      <Box
                        className="pageInnerWrapper"
                        align="center"
                        alignSelf="center"
                        direction="row-responsive"
                        gap="medium"
                        justify="center"
                        margin={{ top: size }}
                        pad={{ horizontal: size }}
                        width="xxlarge"
                      >
                        <Sidebar
                          className="noprint"
                          background="#fff"
                          round="small"
                          width="medium"
                          data={menuState}
                          dividerColor="brand"
                          history={history}
                          select={currentOrgs}
                          selected={activeOrgState ? activeOrgState.id : -1}
                          selectActive={selectActiveOrg}
                        />
                        <Switch>
                          <Route
                            exact
                            path="/service/subscription"
                            render={props => (
                              <Elements stripe={stripePromise}>
                                <ServiceSubscription {...props} />
                              </Elements>
                            )}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/incident/search"
                            component={IncidentSearch}
                            activeOrg={activeOrgState}
                          />

                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/incident/search/global"
                            component={IncidentSearchGlobal}
                            activeOrg={activeOrgState}
                          />

                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/ai/insights"
                            component={AILanding}
                            activeOrg={activeOrgState}
                          />

                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/ai/insights/incident"
                            component={AIIncidentTools}
                            activeOrg={activeOrgState}
                          />

                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/ai/insights/serviceuser/:userid?"
                            component={AIStudent}
                            activeOrg={activeOrgState}
                          />


                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/ai/insights/service"
                            component={AIService}
                            activeOrg={activeOrgState}
                          />


                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/incident/ai"
                            component={AIIncidentAlpha}
                            activeOrg={activeOrgState}
                          />

                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/incident/aisupport"
                            component={AIIncidentTools}
                            activeOrg={activeOrgState}
                          />


                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/incident/drafts"
                            component={IncidentDrafts}
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/plans"
                            component={SmartPlans}
                            activeOrg={activeOrgState}
                          />

                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/intervention/report/search"
                            component={ReportSearch}
                            submissionType="interventionreport"
                            reportTitle="Intervention"
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/report/interventionreport"
                            component={InterventionReport}
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/involvement/report/search"
                            component={ReportSearch}
                            submissionType="involvementreport"
                            reportTitle="Involvement"
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/report/involvementreport"
                            component={InvolvementReport}
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/medicalIntervention/report/search"
                            component={ReportSearch}
                            submissionType="medicalinterventionreport"
                            reportTitle="Medical Intervention"
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/incidentreportExtras/report/search"
                            component={ReportSearch}
                            submissionType="incidentreportExtras"
                            reportTitle="Incident Report Extras"
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/report/incidentreportExtras"
                            component={PILExtrasReport}
                            submissionType="incidentreportExtras"
                            reportTitle="Incident Report Extras"
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/report/medicalinterventionreport"
                            component={MedicalInterventionReport}
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/incident/report/search"
                            component={ReportSearch}
                            submissionType="incidentreport"
                            reportTitle="Global Incident"
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/report/incidentreport"
                            component={IncidentReport}
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/reports"
                            component={Reports}
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/behaviour/drafts"
                            component={BehaviourDrafts}
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/behaviour/:userid/edit"
                            component={BehaviourPlan}
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/log/:userid/edit/:formId?"
                            component={ConcernEdit}
                            activeOrg={activeOrgState}
                          />

                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/incident/:userid/ai/:formId"
                            component={AIIncident}
                            activeOrg={activeOrgState}
                          />

                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/incident/:userid/edit/:formId?"
                            component={IncidentEdit}
                            activeOrg={activeOrgState}
                          />

                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/incident/high-level/:userid/edit/:formId?"
                            component={IncidentHighLevelEdit}
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/incident/:userid/completion"
                            component={IncidentEditCompletion}
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            exact
                            path="/role/:roleid/edit"
                            component={RoleEdit}
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/roles"
                            component={RoleList}
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/service/:organisationid/edit"
                            component={OrganisationEdit}
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/services"
                            component={OrganisationList}
                            activeOrg={activeOrgState}
                          />
                          <Route
                            exact
                            path="/mylogin"
                            render={props => (
                              <Elements stripe={stripePromise}>
                                <MyLogin {...props} handleLogin={handleLogin} />
                              </Elements>
                            )}
                          />
                          {/* <Route
                            exact
                            path="/user/signup"
                            render={props => (
                              <Elements stripe={stripePromise}>
                                <UserSignup {...props} handleLogin={handleLogin} />
                              </Elements>
                            )}
                          /> */}
                          <Route path="/user/activate" component={UserActivate} />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/user/:userid/edit"
                            component={UserEdit}
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/serviceuser/new/upload"
                            component={UserUpload}
                            activeOrg={activeOrgState}
                            type="serviceuser"
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/user/new/upload"
                            component={UserUpload}
                            activeOrg={activeOrgState}
                            type="user"
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/users"
                            component={UserList}
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/servicesetup"
                            component={ServiceSetup}
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/serviceusers"
                            component={ServiceUserList}
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/serviceuser/:userid/edit"
                            component={ServiceUserEdit}
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/serviceuser/:userid/profile"
                            component={ServiceUserProfile}
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/content/:contentid/edit"
                            component={ContentEdit}
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/content"
                            component={ContentList}
                            activeOrg={activeOrgState}
                          />
                          <Route path="/privacy" component={Privacy} />
                          <Route path="/tandc" component={TandC} />
                          <Route
                            exact
                            path="/logout"
                            render={props => (
                              <Logout
                                {...props}
                                handleLogout={handleLogout}
                                loggedInStatus={loginState}
                              />
                            )}
                          />
                          {/* NOTE: Use this render method to pass props to the component */}
                          <Route
                            exact
                            path="/login"
                            render={props => (
                              <LoginScreen
                                {...props}
                                handleLogin={handleLogin}
                                handleLogout={handleLogout}
                                loggedInStatus={loginState}
                              />
                            )}
                          />
                          <Route
                            exact
                            path="/reset-password"
                            render={props => (
                              <PasswordReset
                                {...props}
                                handlePasswordReset={handlePasswordReset}
                              />
                            )}
                          />

                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/record"
                            component={Record}
                            activeOrg={activeOrgState}
                          />


                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/dashboard"
                            component={Dashboard}
                            loggedInStatus={loginState}
                            activeOrg={activeOrgState}
                          />
                          <PrivateRoute
                            terms={terms}
                            exact
                            path="/"
                            component={Dashboard}
                            loggedInStatus={loginState}
                            activeOrg={activeOrgState}
                          />
                          <Route
                            render={() => (
                              <h1>404: page not found - to be created</h1>
                            )}
                          />
                        </Switch>
                      </Box>
                    </Box>
                  );
                }}
              </ResponsiveContext.Consumer>
            </Grommet>
          </Router>
        </GlobalContext.Provider>
      </ErrorBoundary>
    </Provider>
  );
}

export default withStore(App);
