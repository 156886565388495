import React from 'react'

import { Box, Button, CheckBox, FormField, Grid, Text } from 'grommet'
import { useHistory } from 'react-router-dom'



import FontAwesome from '../../../../components/compound/fontAwesome/FontAwesome'

import LocalStorageService from '../../../../services/LocalStorageService'
import { capitalize } from '../../../../utils/text'
import constants from '../../../../utils/constants'
import { useEffect, useState } from 'react'

const IncidentLinkBar = (props) => {
  // replace the name of the service user with the stored term
  const terms = LocalStorageService.get('terms')
  const activeOrg = LocalStorageService.get('activeOrg') 
  const user = LocalStorageService.get('userDetails')

  const history = useHistory()

  const [links, setLinks] = useState([])

  useEffect(() => {
    let newLinks = []
    if (activeOrg?.safeguardingLeads?.includes(user?.email?.toLocaleLowerCase())) {
        newLinks.push({
            href: '/incident/search/global',
            icon: ['fal', 'search'],
            title: 'Incident Search',
        })
    } else {
        newLinks.push({
            href: '/incident/search/global',
            icon: ['fal', 'search'],
            title: 'Incident Search',
        })
    }
    newLinks.push({
        href: '/incident/drafts',
        icon: ['fal', 'pencil'],
        title: 'Incomplete Incidents',
    })
    setLinks(newLinks)
    
  }, [])



//   history.location.pathname

  return (
    <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small' flex='grow'>
        {/* <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small', bottom: 'none' }}>
            <Text size='large'>Quick Behaviours</Text>
        </Box> */}
        <Box direction='row-responsive' justify='start' pad={{ horizontal: 'medium', vertical: 'small' }}>
        <Box alignSelf='center' direction='row-responsive' gap='xsmall'>
            {links.map((link, index) => (
                <Button secondary={link.href !== history.location.pathname} primary={link.href === history.location.pathname} label={<Text size='medium'><FontAwesome icon={link.icon} /> {link.title}</Text>} margin={{ horizontal: 'xsmall' }} pad={{ horizontal: 'xsmall' }} onClick={() => history.push(link.href)} target='_self' />)
            )}
        </Box>
        </Box>
    </Box>
  )
}


// menuSetup.push({
    //   linkHref: '/incident/drafts',
    //   icon: 'pencil',
    //   linkTitle: 'Incomplete Incidents',
    // });


export default IncidentLinkBar



