import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import ReportService from '../../services/ReportService'
import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import UserTagService from '../../services/UserTagService'

import moment from 'moment'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Anchor from '../../components/simple/anchor/Anchor'
import Box from '../../components/simple/box/Box'
import Text from '../../components/simple/text/Text'

import ResponsiveGrid from '../../components/compound/responsiveGrid/ResponsiveGrid'

// Shared Components
import BreadcrumbBar from '../shared/BreadcrumbBar'

function AILanding (props) {
  const history = useHistory()

  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')
  const activeUser = LocalStorageService.get('userDetails')

  return (
    <>
      <BreadcrumbBar
        path={
          <>
            <Anchor href="/">BehaviourSmart</Anchor>
            <Text color="brand" size="xsmall">
              {history.location.pathname}
            </Text>
          </>
        }
      >
        AI Insights
      </BreadcrumbBar>
    
      <Box
        background="white"
        direction="column"
        gap="small"
        margin={{ bottom: 'small' }}
        round="small"
      >
        <Box
          justify="between"
          pad={{ horizontal: 'medium', vertical: 'small' }}
        >
          <Text color="grey">
            [NEEDS UPDATING] Get AI Insights on your data. This is a beta feature and may not be accurate. Please use with caution.
          </Text>
        </Box>
      </Box>

      {/* {(aiResponse || aiError) && <Box
        background="white"
        direction="column"
        gap="small"
        margin={{ bottom: 'small' }}
        round="small"
      >
        <Box
          justify="between"
          pad={{ horizontal: 'medium', vertical: 'medium' }}
        >
        <Text size="large" style={{ width: '100%'}}>{availableActions[selectedAction]}</Text>

          <Text margin={{ top: 'xsmall' }} size="xlarge">
            {aiResponse && <>
                <Text style={{ whiteSpace: 'pre-line' }}>{aiResponse}</Text>
            </>}
            {aiError && <Text color='red'>There was an error processing your request. Please try again.</Text>}
          </Text>
        </Box>
      </Box>} */}

      <ResponsiveGrid
        rows={['auto']}
        columns={{
          small: ['auto'],
          medium: ['33%', '33%', '33%'],
          large: ['33%', '33%', '33%'],
          xlarge: ['33%', '33%', '33%'],
        }}
        gap="small"
      >


        <Box background={'primary'} margin="xsmall" pad="small" round="small">
          <Box height="100px" onClick={() => history.push('/ai/insights/incident')}>
            <Text size="xxlarge" weight="bold" textStyle={{ underline: 'none' }} textAlign="center" color='white'>
                Incident
            </Text>
            <Text textStyle={{ underline: 'none' }} textAlign="center" color='white'>View insights on a particular incident</Text>
          </Box>
        </Box>
        <Box background={'primary'} margin="xsmall" pad="small" round="small">
          <Box height="100px" onClick={() => history.push('/ai/insights/serviceuser/')}>
            <Text size="xxlarge" weight="bold" textStyle={{ underline: 'none' }} textAlign="center" color='white'>
                Student
            </Text>
            <Text textStyle={{ underline: 'none' }} textAlign="center" color='white'>View insights on a particular student</Text>
          </Box>
        </Box>
        <Box background={'primary'} margin="xsmall" pad="small" round="small">
          <Box height="100px" onClick={() => history.push('/ai/insights/service')}>
            <Text size="xxlarge" weight="bold" textStyle={{ underline: 'none' }} textAlign="center" color='white'>
                Service
            </Text>
            <Text textStyle={{ underline: 'none' }} textAlign="center" color='white'>Ask question about incidents across your service</Text>
          </Box>
        </Box>
      </ResponsiveGrid>
    </>
  );
}

export default AILanding
