




import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { getObjectByValue } from '../../utils/objects'

import LocalStorageService from '../../services/LocalStorageService'
import NotificationService from '../../services/NotificationService'
import FormService from '../../services/FormService'

// Appt Components
import Box from '../../components/simple/box/Box'
import Button from '../../components/simple/button/Button'
import Divider from '../../components/simple/divider/Divider'
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome'
import Form from '../../components/simple/form/Form'
import FormField from '../../components/simple/formField/FormField'
import Layer from '../../components/simple/layer/Layer'
import Select from '../../components/simple/input/Select'
import H4 from '../../components/simple/heading/H4.js'
import Text from '../../components/simple/text/Text'
import { ResponsiveContext } from 'grommet'

function Logs (props) {
    const history = useHistory()

    const [loading, setLoading] = useState(false)

    // Concern list on display ie. could be filtered
    const [concerns, setConcerns] = useState([])

    // All Concerns
    const [concernsAll, setConcernsAll] = useState([])

    const [submissions, setSubmissions] = useState([])

    // Display Concern full screen?
    const [displayConcernOpen, setDisplayConcernOpen] = useState(false)
    const [concernFull, setConcernFull] = useState()


    const apiToken = LocalStorageService.get('apiToken')
    const activeOrg = LocalStorageService.get('activeOrg')
    const params = useParams()
    const userId = params.userid

    const goBack = () => {
        history.goBack()
    }     

    useEffect(() => {
        (async () => {
            if (!userId) { return }
            
            setLoading(true)
            await getConcerns()
            setLoading(false)
    
            // setConcernValues(previousValues => ({
            // ...previousValues,
            // student: userId
            // }))

        })()
    }, [userId])

    const getConcerns = async () => {
        const params = {
            orgId: activeOrg.id,
            fields: 'id',
            limit: 300,
            sort: 'id DESC'
        }
    
        const where = {
            type: 'concern',
            status: 'Published',
            subject: userId
        }
    
        const concernSubmissions = await FormService.getSubmissions(apiToken, params, where)
        if (concernSubmissions.error) {
            setSubmissions(null)
        } else {
            if (concernSubmissions?.data) {
            const submissionIds = concernSubmissions.data.map((submission, index) =>
                submission.id
            )
            setSubmissions(submissionIds)
            }
        }
    }

    // Submissions have been found, get the Responses
    useEffect(() => {
    (async () => {
        if (submissions?.length) {
        const savedConcerns = []

        submissions.forEach((submission) => {
            // savedConcerns[submission] = {
            //   concernDate: '',
            //   action: ''
            // }
            savedConcerns.push(
            {
                submission: submission,
                concernDate: '',
                action: '',
                id: ''
            }
            )
        })
        const params = {
            fields: 'id,questionKey,response,responseType,submission',
            orgId: activeOrg.id,
            limit: 4000,
            sort: 'id DESC'

        }

        const where = {
            submission: submissions
        }

        const concernResponses = await FormService.getResponses(apiToken, params, where)
        if (concernResponses?.error) {
            NotificationService.error(concernResponses.error)
        } else {
            // const concernToSave = {}

            if (concernResponses?.data.length) {
            concernResponses.data.forEach((response) => {
                if (response.questionKey && response.id) {
                buildConcernHistory(savedConcerns, response, response.questionKey)
                buildConcernHistory(savedConcerns, response, 'id')
                // switch (response.questionKey) {
                //   case 'concernDate':
                //     buildConcernHistory(savedConcerns, response, 'concernDate')
                //     break
                //   case 'action':
                //     buildConcernHistory(savedConcerns, response, 'action')

                //     // Add action id to display full screen later
                //     buildConcernHistory(savedConcerns, response, 'id')
                //     break
                //   default:
                //     break
                // }
                }
            })

            setConcernsAll(savedConcerns)
            setConcerns(savedConcerns)
            }
            }
          } else {
            setConcernsAll(null)
            setConcerns(null)
          }
        })()
      }, [submissions])

      // This adds to an array of historical Concerns (savedConcerns) for display purposes
        // key = key to be added
        // response = historical Concern
        const buildConcernHistory = (savedConcerns, response, key) => {
            savedConcerns.find((obj, index) => {
            if (obj.submission === response.submission) {
                if (key === 'id') {
                savedConcerns[index][key] = response.id
                } else {
                savedConcerns[index][key] = response.responseType === 'number' ? parseInt(response.response) : response.response
                }
            }
            })
        }

      // Open and Close Full Concern disaply
        const onOpenDisplayConcern = (concernResponseId) => {
          if (concernResponseId) {
            const selectedConcern = getObjectByValue(concerns, 'id', concernResponseId)
      
            setConcernFull(selectedConcern.concern)
            setDisplayConcernOpen(true)
          }
        }
      
        const onCloseDisplayConcern = () => {
          setDisplayConcernOpen(undefined)
        }
      
        // Edit a Concern
        const onEditConcern = (concernResponseId) => {
          if (concernResponseId) {
            const selectedConcern = getObjectByValue(concerns, 'id', concernResponseId)
            history.push(`/log/${userId}/edit/${selectedConcern?.submission}`)
          }
        }
      
        const deleteConcern = async (concernResponseId) => {   
          await FormService.updateSubmission(apiToken, concernResponseId, { status: 'Archived' })
          // window.location.reload() // this is not ideal
          await getConcerns()
        }
      
        const confirmDeleteConcern = async (concernResponseId) => {   
          if (window.confirm('Are you sure you want to delete this?')) {
      
            const selectedConcern = getObjectByValue(concerns, 'id', concernResponseId)
      
            const submissionToDelete = parseInt(selectedConcern.submission)
            deleteConcern(submissionToDelete)
          }
        }
      
        const cancelEdit = async () => {
        //   setSelectedConcernId(null)
        //   setConcernValues(defaultConcern)
        }

  return (
    <Box gridArea='main' background='white' direction='column' gap='small' round='small' margin={{ bottom: 'large' }}>
      <Divider color='primary' />
      <Box
        gap='small'
        round='small'
      >
        {!loading && <>
            {concernsAll?.length > 0
                ? concernsAll?.map((item) => (
                item.concern
                    ? <Box direction='column' key={item.id} margin={{ horizontal: '10px', top: '10px' }}>
                        <Box direction='row' justify='between'>
                        <Text color='brand' size='medium'>{item.concernDate || 'N/A'}</Text>
                        <Box direction='row' gap='medium' justify='end'>
                            <Button icon={<Text><FontAwesome icon={['fal', 'eye']} /></Text>} onClick={() => onOpenDisplayConcern(item.id)} plain />
                            <Button icon={<Text><FontAwesome icon={['fal', 'edit']} /></Text>} onClick={() => onEditConcern(item.id)} plain />
                            <Button icon={<Text><FontAwesome icon={['fal', 'trash']} /></Text>} onClick={() => confirmDeleteConcern(item.id)} plain />
                        </Box>
                        </Box>
                        <Text size='small' truncate weight='bold'>{item.concern || 'No concern recorded'}</Text>
                    </Box>
                    : <></>)
                )
                : <Text>No Logs available</Text>}
        </>}
        {loading && <Text>Loading...</Text>}
      </Box>
      {displayConcernOpen &&
        <ResponsiveContext.Consumer>
        {responsive => (
            <Layer
            margin={responsive === 'small' ? 'medium' : 'large'}
            onClickOutside={onCloseDisplayConcern}
            onEsc={onCloseDisplayConcern}
            position='center'
            responsive={false}
            >
            <Box
                flex
                overflow='auto'
                pad='medium'
                width={responsive === 'small' ? 'medium' : 'large'}
            >
                <Text>{concernFull}</Text>
            </Box>
            </Layer>)}
        </ResponsiveContext.Consumer>}
    </Box>
  )
}
export default Logs
