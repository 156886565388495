import React, { useState, useEffect } from 'react'

import { useHistory } from 'react-router-dom'

import ReportService from '../services/ReportService'
import LocalStorageService from '../services/LocalStorageService'
import NotificationService from '../services/NotificationService'
import UserTagService from '../services/UserTagService'
import UserService from '../services/UserService';

import moment from 'moment'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Anchor from '../components/simple/anchor/Anchor'
import Box from '../components/simple/box/Box'
import Text from '../components/simple/text/Text'
import Select from '../components/simple/input/Select'
import Button from '../components/simple/button/Button'
import FontAwesome from '../components/compound/fontAwesome/FontAwesome'

import ResponsiveGrid from '../components/compound/responsiveGrid/ResponsiveGrid'

// Shared Components
import BreadcrumbBar from './shared/BreadcrumbBar'
import { capitalize } from '../utils/text'

function Record (props) {
    const history = useHistory()

    const apiToken = LocalStorageService.get('apiToken')
    const activeOrg = LocalStorageService.get('activeOrg')
    const activeUser = LocalStorageService.get('userDetails')
    const terms = LocalStorageService.get('terms');

    const [studentValues, setStudentValues] = useState([]);
    const [activeStudent, setActiveStudent] = useState(null);
    
    const [selectedAction, setSelectedAction] = useState('custom');
    const [availableActions, setAvailableActions] = useState([]);

    const [showClicker, setShowClicker] = useState(false);

    const [quickBehaviours, setQuickBehaviours] = useState([
        'Throwing',
        'Yelling',
        'Swearing',
        'Wandering'
      ])

    const getUsers = async (type = 'user') => {
        const params = {
            fields: 'id,firstName,lastName,reference,type',
            limit: 1000,
            orgId: activeOrg.id,
            type,
        };

        //   setLoading(true);
        const users = await UserService.getUsers(apiToken, params);
        if (!users || users?.error) {
            // NotificationService.error(users.error)
        } else {
            // if (!unmounted) {
            if (users?.error) {
                setStudentValues(null);
            } else if (users?.data) {
                var studentUsers = [];
                for (const thisUser of users.data) {
                if (thisUser.firstName && thisUser.lastName) {
                    studentUsers.push({
                    id: thisUser.id,
                    learnerNumber: thisUser.reference,
                    name: thisUser.firstName + ' ' + thisUser.lastName,
                    });
                }
                }
                studentUsers = studentUsers.sort((a, b) =>
                a.name.localeCompare(b.name),
                );
                setStudentValues(studentUsers);
            }
            // }
        }
        
    }

    const selectActionHandler = (action) => {

        if (action.showClicker) {
            setShowClicker(true);
        } else {
            history.push(action.href)
        }

    }

    const recordQuickBehaviour = (behaviour) => {
        NotificationService.success(capitalize(behaviour) + ' Recorded')
    }
  
    const populateActions = async (id = 'new') => {
        let newActions = []

        if (activeOrg?.plan === 'Plus') {
            newActions = newActions.concat([
                {
                    'title' : 'Incident with Intervention',
                    'href' : `/incident/high-level/${id}/edit`,
                    'description' : 'Record Incident involving Restrictive Physical Intervention',
                },
                {
                    'title' : 'Quick Incident',
                    'href' : `/incident/${id}/edit`,
                    'description' : 'Record Quick Incident',
                }, 
                {
                    'title' : 'Smart Plan Builder',
                    'href' : `/behaviour/${id}/edit`,
                    'description' : 'Create a new Smart Plan',
                }
            ])
        } else {
            newActions = newActions.concat([
                {
                    'title' : 'Incident',
                    'href' : `/incident/${id}/edit`,
                    'description' : 'Record Incident',
                }
            ])
        }

        newActions = newActions.concat([
            {
                'title' : 'Behaviour Log',
                'href' : `/log/${id}/edit`,
                'description' : 'Track behaviour over time',
            },
            // {
            //     'title' : 'Behaviour Clicker',
            //     'href' : `/record`,
            //     'showClicker' : true,
            //     'description' : 'Track quick behaviours',
            // }
        ])

        setAvailableActions(newActions);
    }
    useEffect(() => {
        populateActions(activeStudent?.id);
    }, [activeStudent]);

    useEffect(() => {
        getUsers('student');
    }, []);

  return (
    <>
      <BreadcrumbBar
        path={
          <>
            <Anchor href="/">BehaviourSmart</Anchor>
            <Text color="brand" size="xsmall">
              {history.location.pathname}
            </Text>
          </>
        }
      >
        Record
      </BreadcrumbBar>
        
        <Box
            background="white"
            direction="column"
            gap="small"
            margin={{ bottom: 'small' }}
            round="small"
            >
            <Box
                justify="between"
                pad={{ horizontal: 'medium', vertical: 'small' }}
            >
                <Text margin={{ top: 'xsmall', bottom: 'xsmall' }} size="xlarge">
                Select {(terms?.serviceUser || 'service user')} 
                </Text>
                <Select
                    onChange={({ option }) => setActiveStudent(option)}
                    labelKey="name"
                    placeholder={
                        'Select ' +
                        (terms?.serviceUser || 'service user')
                    }
                    options={studentValues}
                    onSearch={text => {
                        // The line below escapes regular expression special characters:
                        // [ \ ^ $ . | ? * + ( )
                        const escapedText = text.replace(
                        /[-\\^$*+?.()|[\]{}]/g,
                        '\\$&',
                        );
                        // Create the regular expression with modified value which
                        // handles escaping special characters. Without escaping special
                        // characters, errors will appear in the console
                        const exp = new RegExp(escapedText, 'i');

                        setStudentValues(
                        props.studentValues.filter(o => exp.test(o.name)),
                        );
                    }}
                    valueKey={{ key: 'id', reduce: false }}
                />
            </Box>
        </Box>
      

      {!activeStudent && <Box
        background="white"
        direction="column"
        gap="small"
        margin={{ bottom: 'small' }}
        round="small"
      >
        <Box
          justify="between"
          pad={{ horizontal: 'medium', vertical: 'small' }}
        >
          <Text color='primary'>
            Select a {(terms?.serviceUser || 'service user')?.toLowerCase()} to continue
          </Text>
        </Box>
      </Box>}
      
      {/* {activeStudent && <ResponsiveGrid
        rows={['auto']}
        columns={{
          small: ['auto'],
          medium: ['33%', '33%', '33%'],
          large: ['33%', '33%', '33%'],
          xlarge: ['33%', '33%', '33%'],
        }}
        gap="small"
      >

        { Object.keys(availableActions).map((key) => (
        <Box background={key === selectedAction ? 'primary' : "white" } margin="xsmall" pad="small" round="small">
          <Box height="50px" onClick={() => console.log(key)}>
            <Text weight="bold" textStyle={{ underline: 'none' }} textAlign="center" color={key === selectedAction ? 'white' : 'primary'}>
                {availableActions[key]}
            </Text>
          </Box>
        </Box>
        ))}
      </ResponsiveGrid>} */}
        
        {activeStudent && <>
        
        
            <Box background='white' direction='column' gap='small' round='small' flex='grow'>
                <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
                    <Text size='large'>
                        <FontAwesome icon={['fal', 'bolt']} /> Behaviour Clicker 
                    </Text>
                </Box>
            </Box>
            <Box direction='row-responsive' justify='start' pad={{ horizontal: 'small', vertical: 'small', top: 'none', bottom: 'medium' }}>
                {quickBehaviours.map((behaviour, index) => (
                <Box background={'primary'} margin="xsmall" pad="small" round="small">
                    <Box onClick={() => recordQuickBehaviour(behaviour) }>
                        <Text size="large" weight="bold" textStyle={{ underline: 'none' }} textAlign="center" color='white'>
                            <FontAwesome icon={['fal', 'plus-circle']} />&nbsp;
                            {behaviour}
                        </Text>
                    </Box>
                </Box> 
                
                )
            )}
            </Box>
    

        {/* <Box alignSelf='center' direction='row-responsive' gap='xsmall'>
                <Button secondary label={<Text size='medium'><FontAwesome icon={['fal', 'plus-circle']} /> {behaviour}</Text>} margin={{ horizontal: 'xsmall' }} pad={{ horizontal: 'xsmall' }} onClick={() => recordQuickBehaviour(behaviour)} target='_self' />
                </Box> */}

            <Box background='white' direction='column' gap='small' margin={{ bottom: 'medium' }} round='small' flex='grow'>
                <Box direction='row-responsive' justify='between' pad={{ horizontal: 'medium', vertical: 'small' }}>
                    <Text size='large'>
                        <FontAwesome icon={['fal', 'plus-circle']} />&nbsp;
                        Record
                    </Text>
                </Box>
            </Box>

            <ResponsiveGrid
                rows={['auto']}
                columns={{
                    small: ['auto'],
                    medium: ['33%', '33%', '33%'],
                    large: ['33%', '33%', '33%'],
                    xlarge: ['33%', '33%', '33%'],
                }}
                gap="small"
                > 
                { Object.keys(availableActions).map((key) => (
                    <Box background={'primary'} margin="xsmall" pad="small" round="small">
                        <Box height="100px" onClick={() => selectActionHandler(availableActions[key]) }>
                            <Text size="xlarge" weight="bold" textStyle={{ underline: 'none' }} textAlign="center" color='white'>
                                {availableActions[key].title}
                            </Text>
                        <Text textStyle={{ underline: 'none' }} textAlign="center" color='white'>{availableActions[key].description}</Text>
                        </Box>
                    </Box> 
                ))}

        </ResponsiveGrid>


      </>}



    </>
  );
}

export default Record
