function capitalize (str) {
  return (str && typeof str === 'string') ? (str?.charAt(0)?.toUpperCase() + str.slice(1)) : ''
}
function lowercase (str) {
  return (str && typeof str === 'string') ? str.toLowerCase() : ''
}

function removeSpaces (str) {
  return str.replace(/\s/g, '')
}

// Add str to targetStr by comma separating them
function commaSeparate (targetStr, str) {
  if (targetStr) {
    targetStr = targetStr + ', ' + str
  } else {
    targetStr = str
  }

  return targetStr
}

export { capitalize, removeSpaces, commaSeparate, lowercase }
