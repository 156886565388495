import React, { useState } from 'react'

// Appt Components
import FontAwesome from '../../components/compound/fontAwesome/FontAwesome.js'
import OptionButtons from '../../components/compound/optionButtons/OptionButtons.js'
import Box from '../../components/simple/box/Box.js'
import Button from '../../components/simple/button/Button.js'
import Divider from '../../components/simple/divider/Divider.js'
import Form from '../../components/simple/form/Form.js'
import FormField from '../../components/simple/formField/FormField.js'
import H2 from '../../components/simple/heading/H2.js'
import TextArea from '../../components/simple/input/TextArea.js'
import Text from '../../components/simple/text/Text.js'
import TextInput from '../../components/simple/input/TextInput.js'

function PostIncidentLearning (props) {
  const [questions, setQuestions] = useState(props.questions)

  const [localIncidentValues, setLocalIncidentValues] = useState(props.incidentValues)

  const previousPage = () => {
    props.previousPage(localIncidentValues)
  }

  const nextPage = () => {
    props.nextPage(localIncidentValues)
  }

  // Submit form values
  const submitForm = async (values) => {
    props.submitForm(values, 'Draft')
  }

  return (
    <Box
      gridArea="main"
      background="white"
      direction="column"
      gap="small"
      round="small"
    >
      <Box
        gap="small"
        margin={{ horizontal: 'small' }}
        pad="small"
        round="small"
      >
        <Box fill direction="row" gap="small">
          <H2 margin={{ vertical: 'none' }}>Post Incident Learning</H2>
          <Button
            label={
              <Text>
                <FontAwesome color="brand" icon={['fas', 'question-circle']} />
              </Text>
            }
            plain
            tip={{
              content: (
                <Box
                  background={{ color: 'white' }}
                  border={{ color: 'brand', size: 'small' }}
                  direction="column"
                  pad="small"
                >
                  <Text>
                    Post incident learning should take place as soon as possible
                    but only when staff and service user are ready.
                  </Text>
                </Box>
              ),
            }}
          />
        </Box>

        <Divider color="primary" margin={{ top: 'none', bottom: 'small' }} />
        <Form
          //   validate='blur'
          onChange={nextValue => {
            setLocalIncidentValues(nextValue);
          }}
          onSubmit={({ value: nextValue }) => {
            submitForm(nextValue);
          }}
          value={localIncidentValues}
        >
          <Box>
            <FormField
              label={
                <Box fill direction="row" gap="small">
                  <Text size="small">{questions[3].questionText}</Text>
                  <Button
                    label={
                      <Text>
                        <FontAwesome
                          color="brand"
                          icon={['fas', 'question-circle']}
                        />
                      </Text>
                    }
                    plain
                    tip={{
                      content: (
                        <Box
                          background={{ color: 'white' }}
                          border={{ color: 'brand', size: 'small' }}
                          direction="column"
                          pad="small"
                        >
                          <Text>
                            Staff should listen to the service users point of
                            view without challenge. Staff should use Empathy,
                            rapport, paraphrasing and summarising. to get a
                            clear idea of what happened.
                          </Text>
                        </Box>
                      ),
                    }}
                  />
                </Box>
              }
              name={questions[3].key}
              required
            >
              <TextArea name={questions[3].key} type="text" />
            </FormField>
            <FormField
              label={
                <Box fill direction="row" gap="small">
                  <Text size="small">{questions[4].questionText}</Text>
                  <Button
                    label={
                      <Text>
                        <FontAwesome
                          color="brand"
                          icon={['fas', 'question-circle']}
                        />
                      </Text>
                    }
                    plain
                    tip={{
                      content: (
                        <Box
                          background={{ color: 'white' }}
                          border={{ color: 'brand', size: 'small' }}
                          direction="column"
                          pad="small"
                        >
                          <Text>
                            Staff should try to ascertain how the trigger made
                            the service user feel.
                          </Text>
                        </Box>
                      ),
                    }}
                  />
                </Box>
              }
              name={questions[4].key}
              required
            >
              <TextArea name={questions[4].key} type="text" />
            </FormField>
            <FormField
              label={
                <Box fill direction="row" gap="small">
                  <Text size="small">{questions[5].questionText}</Text>
                  <Button
                    label={
                      <Text>
                        <FontAwesome
                          color="brand"
                          icon={['fas', 'question-circle']}
                        />
                      </Text>
                    }
                    plain
                    tip={{
                      content: (
                        <Box
                          background={{ color: 'white' }}
                          border={{ color: 'brand', size: 'small' }}
                          direction="column"
                          pad="small"
                        >
                          <Text>
                            The aim of this process is to plan other strategies
                            that can be implemented by the service user and
                            staff the next time the service user feels the way
                            they feel when they experience the trigger.
                          </Text>
                        </Box>
                      ),
                    }}
                  />
                </Box>
              }
              name={questions[5].key}
              required
            >
              <TextArea name={questions[5].key} type="text" />
            </FormField>
            <FormField
              label={questions[6].questionText}
              name={questions[6].key}
              required
            >
              <TextArea name={questions[6].key} type="text" />
            </FormField>
            <FormField
              label={questions[7].questionText}
              name={questions[7].key}
            >
              <TextArea name={questions[7].key} />
            </FormField>
            <FormField
              label={questions[8].questionText}
              name={questions[8].key}
            >
              <TextArea name={questions[8].key} />
            </FormField>

            {props.enablePILExtras ? (
              <>
                <Divider
                  color="primary"
                  margin={{ top: 'none', bottom: 'small' }}
                />
                <H2 margin={{ vertical: 'none', bottom: 'small' }}>
                  Discriminatory, Prejudiced and other Behaviour
                </H2>
                <Divider
                  color="primary"
                  margin={{ top: 'none', bottom: 'small' }}
                />
                <FormField
                  label={questions[9].questionText}
                  name={questions[9].key}
                  required
                >
                  <OptionButtons
                    id={questions[9].key}
                    name={questions[9].key}
                    value={localIncidentValues[questions[9].key] || 'No'}
                  />
                </FormField>
                <FormField
                  label={questions[10].questionText}
                  name={questions[10].key}
                  required
                >
                  <OptionButtons
                    id={questions[10].key}
                    name={questions[10].key}
                    value={localIncidentValues[questions[10].key] || 'No'}
                  />
                </FormField>
                <FormField
                  label={questions[11].questionText}
                  name={questions[11].key}
                  required
                >
                  <OptionButtons
                    id={questions[11].key}
                    name={questions[11].key}
                    value={localIncidentValues[questions[11].key] || 'No'}
                  />
                </FormField>
                <FormField
                  label={questions[12].questionText}
                  name={questions[12].key}
                  required
                >
                  <OptionButtons
                    id={questions[12].key}
                    name={questions[12].key}
                    value={localIncidentValues[questions[12].key] || 'No'}
                  />
                </FormField>
                <FormField
                  label={questions[13].questionText}
                  name={questions[13].key}
                  required
                >
                  <OptionButtons
                    id={questions[13].key}
                    name={questions[13].key}
                    value={localIncidentValues[questions[13].key] || 'No'}
                  />
                </FormField>
                <FormField
                  label={questions[14].questionText}
                  name={questions[14].key}
                  required
                >
                  <OptionButtons
                    id={questions[14].key}
                    name={questions[14].key}
                    value={localIncidentValues[questions[14].key] || 'No'}
                  />
                </FormField>
                <FormField
                  label={questions[15].questionText}
                  name={questions[15].key}
                  required
                >
                  <OptionButtons
                    id={questions[15].key}
                    name={questions[15].key}
                    value={localIncidentValues[questions[15].key] || 'No'}
                  />
                </FormField>
                <FormField
                  label={questions[16].questionText}
                  name={questions[16].key}
                  required
                >
                  <OptionButtons
                    id={questions[16].key}
                    name={questions[16].key}
                    value={localIncidentValues[questions[16].key] || 'No'}
                  />
                </FormField>
                <FormField
                  label={questions[17].questionText}
                  name={questions[17].key}
                  required
                >
                  <OptionButtons
                    id={questions[17].key}
                    name={questions[17].key}
                    value={localIncidentValues[questions[17].key] || 'No'}
                  />
                </FormField>
                <FormField
                  label={questions[18].questionText}
                  name={questions[18].key}
                  required
                >
                  <OptionButtons
                    id={questions[18].key}
                    name={questions[18].key}
                    value={localIncidentValues[questions[18].key] || 'No'}
                  />
                </FormField>
                <Divider
                  color="primary"
                  margin={{ top: 'none', bottom: 'small' }}
                />
                <H2 margin={{ vertical: 'none', bottom: 'small' }}>
                  Exclusions
                </H2>
                <Divider
                  color="primary"
                  margin={{ top: 'none', bottom: 'small' }}
                />
                <Box direction="row" gap="small">
                  <FormField
                    label={questions[19].questionText}
                    name={questions[19].key}
                    width="125px"
                    required
                  >
                    <OptionButtons
                      id={questions[19].key}
                      name={questions[19].key}
                      value={localIncidentValues[questions[19].key] || 'No'}
                    />
                  </FormField>

                  {localIncidentValues[questions[19].key] === 'Yes' && <FormField
                    label={questions[21].questionText}
                    name={questions[21].key}
                    required
                  >
                    <TextInput
                      name={questions[21].key}
                      type="number"
                    />
                  </FormField>}

                </Box>
                <Box direction="row" gap="small">
                  <FormField
                    label={questions[20].questionText}
                    name={questions[20].key}
                    width="125px"
                    required
                  >
                    <OptionButtons
                      id={questions[20].key}
                      name={questions[20].key}
                      value={localIncidentValues[questions[20].key] || 'No'}
                    />
                  </FormField>

                  {localIncidentValues[questions[20].key] === 'Yes' && <FormField
                    label={questions[22].questionText}
                    name={questions[22].key}
                    required
                  >
                    <TextInput
                      name={questions[22].key}
                      type="number"
                    />
                  </FormField>}
                </Box>
              </>
            ) : (
              <></>
            )}

            <Divider
              color="primary"
              margin={{ top: 'medium', bottom: 'none' }}
            />
          </Box>

          <Box
            direction="row"
            gap="small"
            justify="between"
            margin={{ top: 'medium' }}
          >
            <Button label="< Back" onClick={() => previousPage()} secondary />
            <Button
              icon={<FontAwesome icon={['fal', 'save']} color="#fff" />}
              label="Save for later"
              onClick={() => submitForm(localIncidentValues)}
            />
            <Button label="Next Page" onClick={() => nextPage()} primary />
          </Box>
        </Form>
      </Box>
    </Box>
  );
}

export default PostIncidentLearning
