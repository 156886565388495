import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Appt Components
import Box from '../../../components/simple/box/Box'
import DataTable from '../../../components/simple/dataTable/DataTable.js'
import Button from '../../../components/simple/button/Button'
import Text from '../../../components/simple/text/Text'
import Divider from '../../../components/simple/divider/Divider'

// Services
import FormService from '../../../services/FormService'
import LocalStorageService from '../../../services/LocalStorageService'

const RelatedIncidents = (props) => {
  const apiToken = LocalStorageService.get('apiToken')
  const activeOrg = LocalStorageService.get('activeOrg')

  const [submissions, setSubmissions] = useState([])
  const [responses, setResponses] = useState([])

  const history = useHistory()

  useEffect(() => {
    // let unmounted = false

    // Get submissions
    const getSubmissions = async () => {

        if (!props.results?.length) {
          return
        }
    
      // Get the submission id's of the responses from the search
      const submissionIds = props.results.map(item => (
        item.submission
      ))

      var params = {
        fields: 'id,user,pdfUrl',
        limit: 1000
      }

      const where = {
        organisationId: activeOrg.id,
        type: 'incidentreport',
        status: 'Published',
        id: submissionIds
      }

      const incidentReportSubmissions = await FormService.getSubmissions(apiToken, params, where)

    //   if (!unmounted) {
        setSubmissions(incidentReportSubmissions.data)
        // console.log(userDetails.data)
    //   }
    }

    getSubmissions()

    // return () => { unmounted = true }
  }, [props.results])

  // useEffect(() => {
  //   // Get responses
  //   const getResponses = async () => {
  //     const submissionIds = []

  //     for (const submission of submissions) {
  //       submissionIds.push(submission.id)
  //     }

  //     // submissions.forEach(submission => {
  //     //   submissionIds.push(submission.id)
  //     // })
  //     const where = {
  //       organisationId: activeOrg.id,
  //       submission: submissionIds
  //     }

  //     console.log('WHERE ', where)
  //     const incidentReportResponses = await FormService.getResponses(apiToken, where)
  //     console.log(incidentReportResponses)
  //     setResponses(incidentReportResponses.data)
  //   }

  //   if (submissions?.length) {
  //     getResponses()
  //   }
  // }, [submissions])

  const resultColumns = [
    {
      property: 'id',
      label: 'Incident No',
      header: <Text>Incident No</Text>,
      render: datum => (
        <Text>besmart-{datum.id}</Text>
      )
    },
    {
      property: 'user',
      label: 'Child',
      header: <Text>Child</Text>
    },
    {
      property: 'yearGroup',
      label: 'Year Group',
      header: <Text>Year Group</Text>
    },
    {
      property: 'studentGroup',
      label: 'Student Group',
      header: <Text>Student Group</Text>
    },
    {
      property: 'actions',
      header: <Text>Download</Text>,
      render: datum => (
        <>
          <Box direction='row' width='100%'>
            {/*
              <Button
                onClick={() => { history.push(`/incident/${datum.id}/edit`) }}
                target='_self'
                plain
                children={
                  <Box pad='5px'>
                    <FontAwesomeIcon icon={['fal', 'edit']} color='#fe8300' />
                  </Box>
                }
              />
            */}

            <Button
              target='_blank'
              plain
              href={datum.pdfUrl}
              // onClick={() => {
              //   console.log(datum.pdfUrl)
              // }}
              children={
                <Box pad='5px'>
                  <FontAwesomeIcon icon={['fal', 'download']} color='#fe8300' />
                  {/* <Download color='primary' size='medium' disabled /> */}
                </Box>
              }
            />
          </Box>
        </>
      )
    }
  ]

  const [showIncidents, setShowIncidents] = useState(false)

  const downloadCSV = () => {
    
    // generate a CSV of data from submissions and download it
    const csvRows = []
    const headers = resultColumns.filter(c => c.label).map(c => c.label)
    csvRows.push(headers.join(','))
    submissions.forEach(submission => {
      const row = []
      headers.forEach(header => {
        if (header === 'Incident No') {
          row.push(`besmart-${submission.id}`)
        } else if (header === 'Child') {
          row.push(submission.user)
        } else if (header === 'Year Group') {
          row.push(submission.yearGroup)
        } else if (header === 'Student Group') {
          row.push(submission.studentGroup)
        } else{ 
          row.push('')
        }
      })
      csvRows.push(row.join(','))
    })
    const csvString = csvRows.join('\n')
    const blob = new Blob([csvString], { type: 'text/csv' })
    const url = URL.createObjectURL(blob)
    window.open(url, '_blank')
  }

  
  return (
    <Box background='white' direction='column' gap='small' round='small' margin={{ top: 'medium', bottom: 'large'}}>
         <Box
            gap='small'
            margin={{ horizontal: 'small' }}
            pad={{ horizontal: 'small', bottom: 'medium', top: 'none' }}
            round='small'
        >   

            <Box direction='row' justify='between' margin={{ top: 'medium', bottom: 'medium' }}>
                <Text margin={{ top: 'small', bottom: 'small' }} size='xlarge'>Additional Actions</Text>
                <Box alignSelf='center' direction='row' gap='xsmall'>
                    <Button size='small' onClick={() => { setShowIncidents(!showIncidents) }} label={showIncidents ? 'Hide Related Incidents' : 'Show Related Incidents'} primary />
                    <Button size='small' onClick={() => { downloadCSV() }} label='Download CSV' primary />
                </Box>

            </Box>
            {showIncidents && <>
                <Divider />
                <Box direction='column' gap='xsmall' margin={{ top: 'none' }}>
                        <Text margin={{ top: 'small', bottom: 'small' }} size='xlarge'>Related Incidents</Text>

                        <Text margin={{ top: 'small', bottom: 'small' }} size='medium'>First 250 Results ({submissions?.length || 0} found)</Text>
                        {submissions?.length > 0 && <DataTable
                            columns={resultColumns}
                            data={submissions}
                            primaryKey='id'
                            // sort={sort}
                            // onSort={setSort}
                        />}
                        {!submissions?.length > 0 && <Text color='#666'>- No results to display -</Text>}
                    
                </Box>  
            </>}
        </Box>    
    </Box>
  )
}

export default RelatedIncidents
