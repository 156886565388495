import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';

// Font Awesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// utils
import { capitalize } from '../../utils/text';
import { search, getUniqueValues } from '../../utils/array';

// Appt Components
import Box from '../../components/simple/box/Box';
import DataTable from '../../components/simple/dataTable/DataTable.js';
import Button from '../../components/simple/button/Button';
import Text from '../../components/simple/text/Text';
import Divider from '../../components/simple/divider/Divider.js';

// Services
import FormService from '../../services/FormService';
import AIService from '../../services/AIService';
import LocalStorageService from '../../services/LocalStorageService';
import UserService from '../../services/UserService';

const AIIncidentResults = props => {
  const apiToken = LocalStorageService.get('apiToken');
  const activeOrg = LocalStorageService.get('activeOrg');
  const terms = LocalStorageService.get('terms')

  const history = useHistory();

  const [submissions, setSubmissions] = useState([]);
  const [responses, setResponses] = useState([]);


  const [aiSystemMessage, setAiSystemMessage] = useState('');
  const [aiPrompt, setAiPrompt] = useState('');
  const [aiResponse, setAiResponse] = useState('');
  const [aiError, setAiError] = useState(false);

  const [availableActions, setAvailableActions] = useState({});

  useEffect(() => {
  
    let serviceUserTerm = terms?.serviceUser ? terms?.serviceUser?.charAt(0)?.toUpperCase() + terms?.serviceUser.slice(1) : 'Service User'

    setAvailableActions({
        'summarise': 'Summarise Report',
        // 'lettertoparents': 'Letter to parents',
        'suggestions': 'Recommend Actions',
        'assess': 'Assess the incident report.',
        'informalletter':  'Draft an informal letter/email',
        'formalletter':  'Draft a formal letter',
        'suggeststaffstrategies':  'Suggest strategies for staff ',
        'suggestindividualstrategies':  'Suggest strategies for the ' + serviceUserTerm,
        'supportindividual': 'How can we best support this ' + serviceUserTerm,
        'moredetailonsuggestedstrategies': 'Give more detail on the suggested strategies',
        'postincidentlearning': 'Help me with post incident learning',
        'whatcanwelearn': 'What can we learn',
        'suggestworkshopideas': 'Suggest workshop ideas',
        'suggestlowlevelresponses': 'Suggest other response to low level behaviours',
        'suggestmediumlevelresponses': 'Suggest other response to medium level behaviours',
        'suggesthighlevelresponses': 'Suggest other response to high level behaviours',
        'possiblefunctions': 'Possible functions of behaviour'
      })
    
  }, []);



  


  const requestIncidentAI = async (userId, submissionId, action) => {

    console.log('requestIncidentAI', userId, submissionId, action)

    setAiPrompt('Thinking...')
    setAiResponse('Thinking...')
    setAiSystemMessage('Thinking...')

    const response = await AIService.queryIncident(apiToken, userId, submissionId, action)

    if (response?.response) {
      setAiPrompt(response?.prompt)
      setAiResponse(response.response)
      setAiSystemMessage(response?.systemMessage)
      setAiError(false)
    } else {
      setAiResponse('')
      setAiError(true)
    }
  }


  const deleteReport = async submissionId => {
    if (window.confirm('Are you sure you want to delete this?')) {
      await FormService.updateSubmission(apiToken, submissionId, {
        status: 'Archived',
      });
      window.location.reload(); // this is not ideal
    }
  };

  const resultColumns = [
    {
      property: 'reference',
      header: <Text>Incident No</Text>,
      render: datum => (
        <Text>{datum.id ? `besmart-${datum.reference}` : ''}</Text>
      ),
    },
    {
      property: 'firstName',
      header: (
        <Text>{capitalize(terms?.serviceUser || 'Service User')}</Text>
      ),
      render: datum => (
        <Text>{`${datum.firstname || ''} ${datum.lastname || ''}`}</Text>
      ),
    },
    {
      property: 'actions',
      header: <Text>Actions</Text>,
      render: datum => (
        <>
          <Box width="100%">
            { Object.keys(availableActions).map((key) => (
              <Button
                onClick={() => {
                  requestIncidentAI(datum.userId, datum.id, key)
                }}
                target="_self"
                plain
                children={
                  <Box pad="5px">
                    <Text color='primary'><FontAwesomeIcon icon={['fal', 'robot']} /> {availableActions[key]}</Text>
                  </Box>
                }
              />
            ))}
            <Divider color='primary' />
          </Box>
        </>
      ),
    },
  ];

  return (
    <>
      <Box direction='column' gap='xsmall' margin={{ top: 'small', bottom: 'medium' }}>
        <Text color='brand' size='large'>AI Summary</Text>
        <Text>Please select an option below to continue</Text>
        {aiResponse && <>
            <Text weight='bold'>System Message</Text>
            <Text style={{ whiteSpace: 'pre-line', marginBottom: 20 }}>{aiSystemMessage}</Text>
            <Text weight='bold'>Prompt</Text>
            <Text style={{ whiteSpace: 'pre-line', marginBottom: 20 }}>{aiPrompt}</Text>
            <Text weight='bold'>Response</Text>
            <Text style={{ whiteSpace: 'pre-line' }}>{aiResponse}</Text>
          </>}
        {aiError && <Text color='red'>There was an error processing your request. Please try again.</Text>}
      </Box>
      <Divider />
      <DataTable
        columns={resultColumns}
        data={props.results}
        primaryKey="id"
        step={2000}
        // sort={sort}
        // onSort={setSort}
      />
      {!props.results.length && <Text>There are no results to display.</Text>}
    </>
  );
};

export default AIIncidentResults;
