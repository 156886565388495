import LocalStorageService from '../services/LocalStorageService.js';
import { lowercase } from './text';


const terms = LocalStorageService.get('terms');

const serviceUserTerm = lowercase(terms?.serviceUser || 'Service User')



const incidentLabels = {
    'summarise': 'Summarise Report',
    'suggestions': 'Recommend Actions',
    'assess': 'Assess the incident report.',
    'informalletter':  'Draft an informal letter/email',
    'formalletter':  'Draft a formal letter',
    'suggeststaffstrategies':  'Suggest strategies for staff ',
    'suggestindividualstrategies':  'Suggest strategies for the ' + serviceUserTerm,
    'supportindividual': 'How can we best support this ' + serviceUserTerm,
    'moredetailonsuggestedstrategies': 'Give more detail on the suggested strategies',
    'postincidentlearning': 'Help me with post incident learning',
    'whatcanwelearn': 'What can we learn',
    'suggestworkshopideas': 'Suggest workshop ideas',
    'suggestlowlevelresponses': 'Suggest other response to low level behaviours',
    'suggestmediumlevelresponses': 'Suggest other response to medium level behaviours',
    'suggesthighlevelresponses': 'Suggest other response to high level behaviours',
    'possiblefunctions': 'Possible functions of behaviour'
}

const studentLabels = {
    "barriersplan": "Produce a draft barriers to learning plan",
    "riskassessmentdraft": "Create a draft Risk Assesment for this individual",
    "commontriggers": "What are this individuals most common triggers",
    "letterweekinformal": "Draft an informal letter based on incidents in the last week",
    "lettermonthinformal": "Draft an informal letter based on incidents in the last month",
    "letter3monthsinformal": "Draft an informal letter based on incidents in the last 3 months",
    "letter6monthsinformal": "Draft an inform letter based on incidents in the last 6 months",
    "letterweekformal": "Draft an formal letter based on incidents in the last week",
    "lettermonthformal": "Draft an formal letter based on incidents in the last month",
    "letter3monthsformal": "Draft an formal letter based on incidents in the last 3 months",
    "letter6monthsformal": "Draft an formal letter based on incidents in the last 6 months",
    "strengths": "What does this individual do well",
    "reportsummary": "Summary of reports",
    "individualinsights": "What can we learn about this individual",
    "suggeststrategies": "Suggest startegies for this individual",
    "postincidentlearning": "How can we support this individual with Post Incident Learning",
    "lowlevelbehaviours": "What Low level behaviours does this individual exhibit",
    "midlevelbehaviours": "What medium level behaviours does this individual exhibit",
    "highlevelbehaviours": "What high level behaviours does this individual exhibit",
    "supportlowbehaviours": "How can we best support this individuals low level behaviours",
    "supportmidbehaviours": "How can we best support this individuals medium level behaviours",
    "supporthighbehaviours": "How can we best support this individuals high level behaviours",
    "behaviourimproving": "Is this individuals behaviour improving",
    "setgoals": "Set goals",
    "behaviourpatterns": "Behaviour Patterns",
    "reduceincidents": "How can we reduce behaviour incidents",
    "improvebehaviour": "How can we improve behaviour",
    "behaviourcausation": "Causation of Behaviour"
}

const studentPreIncidentLabels = {
    "barriersplan": "Produce a draft barriers to learning plan",
    "riskassessmentdraft": "Create a draft Risk Assesment for this individual",
    "commontriggers": "What are this individuals most common triggers",
    "setgoals": "Set goals",
    "reduceincidents": "How can we reduce behaviour incidents",
    "behaviourcausation": "Causation of Behaviour"
}

const studentIncidentLabels = {
    "suggeststrategies": "Suggest strategies for this individual",
    "lowlevelbehaviours": "What Low level behaviours does this individual exhibit",
    "midlevelbehaviours": "What medium level behaviours does this individual exhibit",
    "highlevelbehaviours": "What high level behaviours does this individual exhibit",
    "supportlowbehaviours": "How can we best support this individuals low level behaviours",
    "supportmidbehaviours": "How can we best support this individuals medium level behaviours",
    "supporthighbehaviours": "How can we best support this individuals high level behaviours",
    "behaviourimproving": "Is this individuals behaviour improving",
}

const studentPostIncidentLabels = {
    "letterweekinformal": "Draft an informal letter based on incidents in the last week",
    "lettermonthinformal": "Draft an informal letter based on incidents in the last month",
    "letter3monthsinformal": "Draft an informal letter based on incidents in the last 3 months",
    "letter6monthsinformal": "Draft an inform letter based on incidents in the last 6 months",
    "letterweekformal": "Draft an formal letter based on incidents in the last week",
    "lettermonthformal": "Draft an formal letter based on incidents in the last month",
    "letter3monthsformal": "Draft an formal letter based on incidents in the last 3 months",
    "letter6monthsformal": "Draft an formal letter based on incidents in the last 6 months",
    "strengths": "What does this individual do well",
    "reportsummary": "Summary of reports",
    "postincidentlearning": "How can we support this individual with Post Incident Learning",
    "behaviourpatterns": "Behaviour Patterns",
}


const serviceLabels = {
    "training6hour": "Create a 6 hour training course based on our needs",
    "training3hour": "Create a 3 hour training course based on our needs",
    "training1hour": "Create a 1 hour training course based on our needs",
    "incidentsummary": "Summary of Incidents",
    "postincidentsupport": "Supporting Post Incident Learning",
    "lowlevelsummary": "Summary of Low Level Incidents",
    "midlevelsummary": "Summary of Medium Level Incidents",
    "highlevelsummary": "Summary of High Level Incidents",
    "incidentlearnings": "What can we learn from our incidents?",
    "strengthssummary": "What do we do well?",
    "sefinfo": "Information for (SEF)",
    "commontriggers": "Most Common Triggers",
    "developmentareas": "What are our areas for development",
    "behaviourimproving": "Is behaviour in our service improving",
    "improvebehaviour": "How can we overall improve behaviour",
    "behaviourcauses": "Causation of behaviour",
}


export { incidentLabels, studentLabels, serviceLabels, studentPreIncidentLabels, studentIncidentLabels, studentPostIncidentLabels }

